/**
 * Video modal with vanilla modal https://www.npmjs.com/package/vanilla-modal
 */

(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var VIDEO_SEL = '[data-video]';
  var MODAL_SEL = '[data-modal-video]';


  /**
   * Init
   */
  function init() {

    if ($(VIDEO_SEL).length > 0) {
        $(VIDEO_SEL).each(function() {
          var bindedThis = this
          DidomiOverride.getUserConsentStatusForVendor('youtube').then(function (res) {
            if (res) {
              var iframe = $(bindedThis).find('iframe')
              if (iframe) {
                iframe.attr('src', 'https://www.youtube.com/embed/' + $(bindedThis).attr('data-video'));
              }
              $(bindedThis).find('.Video-blocked').hide();
              iframe.show();
            }
          })
        })
    }

    if ($(MODAL_SEL).length > 0) {
      var vanillaModal = new VanillaModal.default()
      $('body').on('click', MODAL_SEL, function (event) {
        event.preventDefault()
          if (Didomi.getUserConsentStatusForVendor(DidomiOverride.youtube)) {
            if ($('#videoPopin').find('iframe')) {
              $('#videoPopin iframe').parent().attr('data-video', $(this).attr('href').split("/")[$(this).attr('href').split("/").length - 1]);
              $('#videoPopin iframe').attr('src', $(this).attr('href'));
            }
            $('.Video-blocked').hide();
            $('.Video-wrapper iframe').show();
          }
        vanillaModal.open('#videoPopin');
      });
    }

  }


})(jQuery);
