/**
 * scrollspy: indicator page scrolling in anchor nav
 */
 (function ($) {
  "use strict";

  $(window).on("load",init);

  // Constants
  var SPY_SEL = '[data-scrollspy="nav"]';
  var SPYITEM_SEL = '.Nav-item';
  var SECTIONS_SEL = '[data-scrollspy="sections"]';
  var SECTION_SEL = '[data-scrollspy="section"]';
  var SECTIONTITLES = [];
  var SECTIONDATA = [];
  var TOLLENS_SEL = '.Row-mainContent .Row-sidebar';
  var W = $(window);


  /**
   * Init
   */
  function init() {
    var sections = $(SECTIONS_SEL);
    var tollens = $(TOLLENS_SEL);
    if (sections.length === 0 || tollens.length > 0) {
      return;
    }

    tagSections();
    createSummary();
    animPercent();
    $(window).on('scroll', animPercent);

  }

  function tagSections() {
    var sections = $(SECTIONS_SEL);
    var section = sections.find('> h2');
    // Wrap content between two <h2> in a <div id="index" data-scrollspy="section">
    section.each(function(index){
      var $set = $(this).nextUntil("h2").addBack();
      $set.wrapAll('<div id="section' + index + '" data-scrollspy="section" />');
      SECTIONTITLES.push($(this).text())
    })
    //  Create the table of values for each section
    $(SECTION_SEL).each(function(index) {
      var item = $(this)
      SECTIONDATA.push({
        index: index,
        title: item.find('> h2').text(),
        offset: item.offset().top,
        height: item.height()
      });
    });
  }

  function createSummary() {
    if(SECTIONDATA.length >= 2 ) {
      var menuContainer = document.createElement('ul');
      //  Create the menu for each section
      SECTIONDATA.forEach(function (item, index) {
        var menuItem = '<li>' +
          '<a class="Nav-item"' +
          'href="#section' + index + '"' +
          'data-scrollspy-start="' + item.offset + '"' +
          'data-scrollspy-height="' + item.height + '">' +
          item.title +
          '<span class="Nav-scroller" aria-hidden></span>' +
          '</a>' +
          '</li>';
        $(menuContainer).append(menuItem).addClass('Nav-list');
      });
      $(SPY_SEL).append(menuContainer).show();
    } else {
      $(".Row-sidebarTitle").hide();
    }
  }

  function animPercent() {

    if(SECTIONDATA.length >= 2 ) {
      var currentScroll = W.scrollTop() + (W.height() / 2);
      var items = $(SPYITEM_SEL);

      items.each(function (index, item) {
        var item = $(item);
        var sectionStart = item.data('scrollspy-start');
        var sectionHeight = item.data('scrollspy-height');
        var sectionEnd = sectionStart + sectionHeight;
        var scrollPosPercent = (currentScroll - sectionStart) / sectionHeight;
        var scroller = item.find('.Nav-scroller')
        var scrollerWidth = scroller.outerWidth()
        if (currentScroll >= sectionStart && currentScroll <= sectionEnd) {
          var scrollerClip = Math.floor(scrollerWidth * scrollPosPercent);
          scroller.css({'clip': 'rect(0px ' + scrollerClip + 'px ' + scrollerWidth + 'px 0px)'});
        } else if (currentScroll > sectionEnd) {
          scroller.css({'clip': 'rect(0px ' + scrollerWidth + 'px ' + scrollerWidth + 'px 0px)'});
        } else if (currentScroll < sectionStart) {
          scroller.css({'clip': 'rect(0px 0px ' + scrollerWidth + 'px 0px)'});
        }
      })
    }
  }


})(jQuery);
