// Dropdown Language

window.onclick = function(event) {
  if (!event.target.matches('.Header-languageBtn')) {
    var dropdowns = document.getElementsByClassName("Header-languageDropdown");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('Header-languageDropdown--show')) {
        openDropdown.classList.remove('Header-languageDropdown--show');
      }
    }
  }
}

$('.switch-language').on('click', function() {
  var lang = $(this).data('language');
  $.cookie('cromologyLang', lang, {expires: 365});
  return true;
});

$('.switch-language-header').on('click', function() {
  document.getElementById("LanguageDropdown").classList.toggle("Header-languageDropdown--show");
  return false;
});


// Pop up Language

(function ($) {
  "use strict";

  $(window).on('load', init);

  function init() {

    var vanillaModal = new VanillaModal.default();

    if (!$.cookie('cromologyLang') && $('#LoadPopup').length) {
      vanillaModal.open('#LoadPopup');
    }

  }
})(jQuery);
