(function ($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var INPUT_SEL = '[data-autocomplete]';
  var RESULTS_SEL = '[data-autocompleteBox]';

  /**
   * Init
   */
  function init() {
    var input = $(INPUT_SEL);
    if (input.length === 0) {
      return;
    }
    var results = $(RESULTS_SEL);
    $(document).on('keyup', input, function(e) {
      var searchText = $(input).val();
      var locationId = $(input).data('location');
      var route = $(input).data('route');
      if (searchText != null && searchText != "") {
        var url = Routing.generate(route, {
          searchText: searchText,
          faqLocationId: locationId
        });
        $.get(url, function (data) {
          results.empty();
          if (data.length > 0) {
            $.each(data, function (key, value) {
              results.append("<li><a href='" + value.location_url + "'> " + highlightKeywords(searchText, value.title) + "</a> <strong><a href='" + value.theme_location_url + "'> " + value.theme_title + "</a></strong></li>")
            })
            results.show();
          } else {
            results.hide();
          }
        })
      } else {
        results.empty().hide();
      }
    })
  }

  function highlightKeywords(keywords, title) {
    var words = keywords.split(" ");
    var mark = "<mark></mark>";
    $.each(words, function(key, value) {
      if(mark.indexOf(value) == -1) {
        var re = new RegExp(value, "i");
        title = title.replace(re, "<mark>$&</mark>");
      }
    })
    return title;
  }

})(jQuery);
