Zolpan.FavoriteColor = Zolpan.FavoriteColor || {};

Zolpan.FavoriteColor = (function ($) {

    var BODY_SEL = 'body';
    var ADD_FAVORITE_COLOR_SEL = '.js-add-favorite-color';
    var REMOVE_FAVORITE_COLOR_SEL = '.js-remove-favorite-color';
    var COLOR_ITEM_SEL = '.ColorItem';
    var TOLLENS_FAVORITE = '.Tollens-ColorItem-Favorite';
    var TOLLENS_ACCOUNT = '.TollensUserAccount';

    var infoModalId = '#infoModalId';
    var infoModalMessageId = '#infoModalMessage';
    var preventFromUpdateColors = [];
    var listenersLoaded = false;
    var vanillaModal = new VanillaModal.default();

    function init() {
        Translator.locale = $('html').attr('lang');
        
        //If Tollens website
        var tollens_favorite = $(TOLLENS_FAVORITE);
        var tollens_account = $(TOLLENS_ACCOUNT);
        
        if(tollens_favorite.length > 0 && tollens_account.length === 0){

        Zolpan.events.vars.done(function (data) {
            // Verify if user is logged
            if (Zolpan.vars.user == false) {
              $('.js-add-favorite-color').addClass('ColorItem-link--disabled');
              $('.bloc-must-be-logged').show();
              $('.bloc-add-favorite-color').not('.changed').show();
            } else {
              $('.bloc-add-favorite-color').not('.changed').show();
              $('.js-add-favorite-color').removeClass('ColorItem-link--disabled');

              // get favorite colors
              if (Zolpan.vars.favorite_colors != false) {
                
                // Verify if he has favorite colors
                $.each(Zolpan.vars.favorite_colors, function (index, value) {
                  value = value.id;
                  var id_addcolor = '.addcolor_' + value;
                  var id_deletecolor = '.deletecolor_' + value;
                  $(id_addcolor).not('.changed').hide();
                  $(id_deletecolor).not('.changed').show();
                });
              }

              // ADD EVENTS
              if(!listenersLoaded) {
                $(BODY_SEL).on('click', ADD_FAVORITE_COLOR_SEL, function () {
                  var colorId = $(this).closest(COLOR_ITEM_SEL).data('colorid');

                  $.post({
                    url: Routing.generate('ajax_add_favorite_color', {'colorId': colorId}),
                    success: function (data) {
                      //TODO : faire en sorte que le click ne soit pas declencher si pas log

                      if (data.success) {
                        addResultMessage = 'color_add_success';
                        $(infoModalMessageId).html(Translator.trans(addResultMessage));
                        vanillaModal.open(infoModalId);
                        // mettre le lien remove et cacher le lien ajout
                        var id_addcolor = '.addcolor_' + colorId;
                        var id_deletecolor = '.deletecolor_' + colorId;
                        $(id_addcolor).hide();
                        $(id_deletecolor).show();
                        $(id_addcolor).addClass('changed');
                      }
                    }
                  })
                });

                $(BODY_SEL).on('click', REMOVE_FAVORITE_COLOR_SEL, function () {
                  var $colorItem = $(this).closest(COLOR_ITEM_SEL);
                  var colorId = $colorItem.data('colorid');

                  $.post({
                    url: Routing.generate('ajax_remove_favorite_color', {'colorId': colorId}),
                    success: function (data) {

                      if (data.success) {
                        removeResultMessage = 'color_remove_success';
                        var tabFavoris = $colorItem.closest("#tab_mesfavoris");

                        if (tabFavoris.length > 0) {
                          $colorItem.remove();
                        }

                        $(infoModalMessageId).html(Translator.trans(removeResultMessage));
                        vanillaModal.open(infoModalId);
                        // mettre le lien remove et cacher le lien ajout
                        var id_addcolor = '.addcolor_' + colorId;
                        var id_deletecolor = '.deletecolor_' + colorId;
                        $(id_addcolor).show();
                        $(id_deletecolor).hide();
                        $(id_addcolor).addClass('changed');
                        $(id_deletecolor).addClass('changed');
                      }
                    }
                  })
                });
                listenersLoaded = true;
              }
            }
        });
      }
    }

    $(document).ready(init);
    $( document ).on('hinclude.load', function(event, req) {
        init();
    });
    $( document ).on('ajaxComplete', function(event, req) {
        init();
    });
})(jQuery);

