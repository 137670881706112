/**
 * Justified gallery init
 */
 (function ($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var GALLERY_SEL = "[data-gallery]";
  var LOADMORE_SEL = "[data-gallery-loadmore]";
  var FACET_SEL = "[data-gallery-facet]";
  var STATE_SEL = "[data-state]";
  var TOLLENS_SEL = ".Gallery-tollens"
  /**
   * Init
   */
  function init() {

    var gallery = $(GALLERY_SEL);
    if (gallery.length === 0) {
      return;
    }
    var tollens = $(TOLLENS_SEL);
    if (tollens.length > 0) {
      return;
    }

    gallery.justifiedGallery({
      rowHeight: 320,
      margins: 12
    });

    infiniteScroll();
    filterGalery();
  }

  function infiniteScroll() {
    var gallery = $(GALLERY_SEL);
    var offset = parseInt($("#offset_inspiration").val());
    var limit = parseInt($("#limit_inspiration").val());
    var total = parseInt($("#total_inspiration").val());

    $(document).off("click", LOADMORE_SEL);
    $(document).on("click", LOADMORE_SEL, function () {
      // Ajax call 10 element suivant
      var tags_selected;
      var array_tags_search = new Array();

      $('.Facets-item--selected').each(function () {
        tags_selected = $(this).attr("data-filter");
        if (tags_selected)
          array_tags_search.push(tags_selected);
      });

      var params = new Object();
      params["offset"] = offset;
      params["limit"] = limit;
      if (array_tags_search.length > 0)
        params["tags_search"] = array_tags_search;

      var zolpan_front_more_inspirations = Routing.generate(
        'zolpan_front_more_inspirations',
        params
      );

      $.ajax({
        url: zolpan_front_more_inspirations,
        success: function (response) {
          gallery.append(response);
          gallery.justifiedGallery('norewind');
          offset = offset + limit;
          if (offset >= total) {
            $(LOADMORE_SEL).hide();
          }
        }
      });
    });
  }

  function filterGalery() {

    $(document).off('click', '.Facets-items-galery.Facets-item--selected');
    $(document).off('click', '.Facets-items-galery:not(.Facets-item--selected)');

    $(document).on('click', '.Facets-items-galery.Facets-item--selected', function (e) {
      e.preventDefault();
      $(this).removeClass('Facets-item--selected');
      callFilterGalery();

    });
    $(document).on('click', '.Facets-items-galery:not(.Facets-item--selected)', function (e) {
      e.preventDefault();
      callFilterGalery($(this));

    });

  }

  function callFilterGalery(selection) {
    var gallery = $('.js-section-Gallery');
    var array_tags_search = new Array();
    var tags_selected;
    $('.Facets-item--selected').each(function () {
      tags_selected = $(this).attr("data-filter");
      if (tags_selected)
        array_tags_search.push(tags_selected);
    });

    if (selection) {
      var selectionId = selection.attr("data-filter");
      if (selectionId)
        array_tags_search.push(selectionId);
    }
    var data = {tags_search: array_tags_search}


    // ajax call
    var zolpan_filter_inspirations = Routing.generate(
      'zolpan_filter_inspirations'
    );
    $.ajax({
      url: zolpan_filter_inspirations,
      data: data,
      type: 'GET',
      success: function (response) {
        gallery.html(response);
        init();
      }
    });
  }

})(jQuery);
