/**
 * Catalog display reset filters button
 */
(function ($) {
  "use strict";

  $(window).ready(init);
  // Constants
  var TOLLENS_SEL = ".Catalog-tollens";
  var CLEARBUTTON = '.js-clear-filters-btn';
  var FILTERSELECTED = '.Facets-item--selected';
  var FILTERCHECKED = '.Facets-item--checked';
  /**
   * Init
   */
  function init() {

    var tollens = $(TOLLENS_SEL);
    if (tollens.length === 0) {
      return;
    }

    //listen to ajax calls and start FN once ajax call is complete
    $(document).ajaxComplete(function() {
      displayFilterButton();
    });
  }

  //Display clear button
  function displayFilterButton(){
    if($(FILTERSELECTED).length > 0 || $(FILTERCHECKED).length > 0) {
      $(CLEARBUTTON).show();
    } else {
      $(CLEARBUTTON).hide();
    }
  }



})(jQuery);