/**
 * Setup Modal
 * Use vanilla-modal lib in node_modules https://github.com/benceg/vanilla-modal
 */
;(function ($) {

  'use strict';

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = '[data-modal-open]';

  /**
   * Init
   */
  function init() {
    var container = $(CONTAINER_SEL);
    if (container.length === 0) {
      return;
    }

    var modal = new VanillaModal.default();
  }


})(jQuery);
