Zolpan.FavoriteHarmony = Zolpan.FavoriteHarmony || {};

Zolpan.FavoriteHarmony = (function ($) {

    var BODY_SEL = 'body';
    var ADD_FAVORITE_HARMONY_SEL = '.js-add-favorite-harmony';
    var REMOVE_FAVORITE_HARMONY_SEL = '.js-remove-favorite-harmony';
    var HARMONY_ACCOUNT_ITEM_SEL = '.js-account-harmony-item';
    var HARMONY_ITEM_SEL = '.ColorItem-harmony';

    var infoModalId = '#infoModalId';
    var infoModalMessageId = '#infoModalMessage';


    var vanillaModal = new VanillaModal.default();

    function init() {
        $(BODY_SEL).on('click', ADD_FAVORITE_HARMONY_SEL, function () {
            var harmonyId = $(this).closest(HARMONY_ITEM_SEL).data('harmonyid');

            $.post({
                url: Routing.generate('ajax_add_favorite_harmony', {'harmonyId': harmonyId}),
                success: function (data) {
                    if (data.success) {
                        $(infoModalMessageId).html('Harmonie ajoutée avec succès.');
                    } else {
                        $(infoModalMessageId).html('Erreur lors de l\'ajout en favori.');
                    }
                    vanillaModal.open(infoModalId);
                }
            })
        });

        $(BODY_SEL).on('click', REMOVE_FAVORITE_HARMONY_SEL, function () {
            var $harmonyItem = $(this).closest(HARMONY_ACCOUNT_ITEM_SEL);
            var harmonyId = $harmonyItem.data('harmonyid');

            $.post({
                url: Routing.generate('ajax_remove_favorite_harmony', {'harmonyId': harmonyId}),
                success: function (data) {
                    if (data.success) {
                        $(infoModalMessageId).html('Harmonie supprimée avec succès.');
                        $harmonyItem.remove();
                    } else {
                        $(infoModalMessageId).html('Erreur lors de la suppression.');
                    }
                    vanillaModal.open(infoModalId);
                }
            })
        });
    }

    $(document).ready(init);
    $( document ).on('hinclude.load', function(event, req) {
        init();
    });

})(jQuery);
