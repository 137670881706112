/**
 * HeroSlider: with slick carousel library in mode modules
 * http://kenwheeler.github.io/slick/
 */
(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = "[data-inspislider]";

  /**
   * Init
   */
  function init() {
    var container = $(CONTAINER_SEL);
    if (container.length === 0) {
      return;
    }
    container.slick({
      dots: false,
      arrows: true,
      fade: true,
      swipe: false,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            swipe: true
          }
        }
      ]
    });
  }
})(jQuery);
