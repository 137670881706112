/**
 * Stickyfy: Stickyfy a content in this parent
 */
(function($) {
  "use strict";

  // Constants
  var HEADER_SEL = 'header.Header';
  var HERO_SEL = 'header.Hero';
  var HEROSEARCH_SEL = '.HeroSearch';
  var PUSHMARKETING_SEL = 'header.Hero + .GridPush';
  var PARENT_SEL = '[data-sticky="parent"]';
  var ELEMENT_SEL = '.Row-mainContent .Row-sidebar [data-sticky="element"]';
  var ARTICLE_SEL = '.Row-mainContent';
  var RICHTEXTEDITO_SEL = '.Row-mainContent > .RichText';
  var SIDEBARCONTAINER_SEL = '.Row-sidebarContainer';
  var STATE = 'data-sticky-state';

  var parent, stickyState;
  var boundaries = {
    top: Infinity,
    bottom: Infinity
  };

  /**
   * Init
   */

  var element = $(ELEMENT_SEL);
  if (element.length === 0) {
    return;
  }
  if($(HEROSEARCH_SEL).length > 0){
    var HERO_HEIGHT = $(HEROSEARCH_SEL).height();
  } else if (element.data("headerheight") !== undefined) {
    var HERO_HEIGHT = element.data("headerheight");
  } else {
    var HERO_HEIGHT = $(HERO_SEL).height();
  }

  if($(PUSHMARKETING_SEL).length > 0){
    HERO_HEIGHT += $(PUSHMARKETING_SEL).height() + 20;
  }

  $(document).ready(function() {
    parent = $(PARENT_SEL);
    setTimeout(updateStickyState, 1000);
    window.addEventListener('scroll', updateStickyState, {cancelable: false});
    var width = $(window).width();

    $(window).on('resize', function() {
      if ($(this).width() !== width) {
        width = $(this).width();
        updateBlocksHeight();
      }
    });
    updateStickyState();
  });

  /**
   * Sets the sidebar container's height to the article's height
   */
  function updateBlocksHeight(){
    //Fit article height to rich text height
    var article = $(ARTICLE_SEL);
    var richText = $(RICHTEXTEDITO_SEL);
    var sidebarContainer = $(SIDEBARCONTAINER_SEL);
    var articleHeight = article.height();
    var richTextHeight = richText.height();

    if(richTextHeight !== articleHeight){
      //reset sidebar size to trigger the correct recalc
      sidebarContainer.css('height', '');
      sidebarContainer.css('min-height', '');

      //update article height
      var richTextHeight = richText.height();
      article.height(richTextHeight);
      article.css('min-height', richTextHeight);
    }

    //Update article or sidebar height
    //in case article is higher than sidebar
    if(article.height() + 70 > sidebarContainer.height()){
      sidebarContainer.height(article.height() + 70); //added 70 for block's padding
      sidebarContainer.css('min-height', article.height() + 70);
    }
    //in case sidebar is higher than article
    else{
      article.height(sidebarContainer.height() - 70);
      article.css('min-height', sidebarContainer.height() - 70);
    }
  }

  /**
   * updating boundaries because it queries the DOM and makes the browser compute Layout and Paint,
   */
  var updateBoundaries = function() {
    var headerHeight = $(HEADER_SEL).height();
    //boundaries top = height main + offset from hero
    boundaries.top = parent.offset().top + HERO_HEIGHT;
    boundaries.bottom = boundaries.top + parent.height() - element.height() - HERO_HEIGHT - headerHeight;

    if($(HEROSEARCH_SEL).length > 0){
      boundaries.top = parent.offset().top - headerHeight;
    }
    if (parent.height() <= element.height()) {
      parent.height(element.height() + 10);
      parent.css('min-height', element.height() + 10);
    }
  };

  function updateStickyState() {
    var scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // Update the cached dimensions of the elements
    updateBoundaries();
    // Update height of the sidebar container since height seems to change after page loading.
    updateBlocksHeight();

    var newState = 'out';

    if (scrollPos < boundaries.top){
      newState = 'out';
    } else if (scrollPos >= boundaries.top && scrollPos <= boundaries.bottom) {
      newState = 'top';
    } else if (scrollPos >= boundaries.bottom) {
      newState = 'bottom';
    }
    // Compare state with cached value to avoid setting the element's attribute N times per second
    if (newState !== stickyState) {
      stickyState = newState;
      element.attr(STATE, newState);
    }
  }
})(jQuery);
