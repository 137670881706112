/**
 * Stickyfy: Stickyfy a content in this parent
 */
(function($) {
  "use strict";

  // Constants
  var PARENT_SEL = '[data-sticky="parent"]';
  var STATE = 'data-sticky-state';
  var ROBBIALAC_SEL = '.ColorFilters-robbialac';
  var ELEMENT_SEL = '[data-sticky="element"]'
  var FOOTER_SEL = 'footer.Footer'
  var SIDEBARTOLLENSSHOP_SEL = '.Shop-Tollens .Row-sidebar';

  var parent, stickyState;
  var boundaries = {
    top: Infinity,
    bottom: Infinity
  };

  /**
   * Init
   */
  var robbialacsel = $(ROBBIALAC_SEL);
  if (robbialacsel.length === 0) {
    return;
  }

  var element = $(ELEMENT_SEL);
  if (element.length === 0) {
    return;
  }

  $(document).ready(function() {
    parent = $(PARENT_SEL);
    setTimeout(updateStickyState, 1000);
    window.addEventListener('scroll', updateStickyState, {cancelable: false});
    updateSidebarTollensColorsShop();
  });

  /**
   * updating boundaries because it queries the DOM and makes the browser compute Layout and Paint,
   */
  var updateBoundaries = _.debounce(function() {
    var footer = $(FOOTER_SEL);

    boundaries.top = 234;
    boundaries.bottom = $(document).height() - element.height() - footer.height() - 234; // 50 for gap

    if (parent.height() <= element.height()) {
      parent.height(element.height())
    }
  }, 1000, {trailing: false});

  function updateStickyState() {
    var scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // Update the cached dimensions of the elements
    updateBoundaries();

    var newState = 'out';

    //fix sidebar overflowing on breadcrumbs
    if($(SIDEBARTOLLENSSHOP_SEL).length > 0){
      if (scrollPos < (boundaries.top)){
        newState = 'out';
      } else if(scrollPos >= (boundaries.top) && scrollPos <= boundaries.bottom) {
        newState = 'top';
      } else if (scrollPos > boundaries.bottom) {
        newState = 'bottom';
      }
    } else {
      if (scrollPos >= boundaries.top && scrollPos <= boundaries.bottom) {
        newState = 'top';
      } else if (scrollPos >= boundaries.bottom) {
        newState = 'bottom';
      }
    }


    // Compare state with cached value to avoid setting the element's attribute N times per second
    if (newState !== stickyState) {
      stickyState = newState;
      element.attr(STATE, newState);
    }
  }

  function updateSidebarTollensColorsShop(){
    if($(SIDEBARTOLLENSSHOP_SEL).length > 0){
      $(SIDEBARTOLLENSSHOP_SEL).css('min-height', $(ELEMENT_SEL).height());
    }
  }

})(jQuery);
