Zolpan.form = Zolpan.form || {};
Zolpan.form.password = (function ($) {

  var $resetPasswordForm = $('#FormResetPassword');

  var similarPasswordErrorMessage = "Les mots de passe doivent correspondre.";

  function init() {
    initCustomValidationRules();
    validateResetPasswordForm();
  }

  function initCustomValidationRules() {
    jQuery.validator.addMethod("zolpan_password", function (value, element) {
        return this.optional(element) || /^(\w|[!_@#$%-]){6,20}$/.test(value);
      }, 'Votre mot de passe doit contenir entre 6 et 20 caractères (caractères spéciaux autorisés : "!", "_", "@", "#", "$", "%", "-")'
    );

  }

  function validateResetPasswordForm() {
    $resetPasswordForm.validate({
      errorClass: "FormItem-info FormItem-info--error",
      rules: {
        "zolpan_reset_password_form[password][second]": {
          "equalTo": "input[name=\"zolpan_reset_password_form[password][first]\"]"
        },
        "zolpan_reset_password_form[password][first]": {
          'zolpan_password': true
        }
      },
      messages: {
        "zolpan_reset_password_form[password][second]": similarPasswordErrorMessage
      },
      errorPlacement: function (error, element) {
        error.insertAfter(element);
      },
      unhighlight: function (element) {
        $(element.closest('.FormItem')).removeClass('FormItem--error');
      }
    });
  }

  $(document).ready(init);

})(jQuery);
