/**
 * MainNav big menu for desktop
 */
(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = ".MainNav";
  var ITEM_SEL = ".MainNav-item";
  var BIGITEM_SEL = ".MainNav-bigNav";
  var CLOSE_SEL = ".MainNav-close";

  /**
   * Init
   */
  function init() {
    var container = $(CONTAINER_SEL);
    if (container.length === 0) {
      return;
    }

    closeAll();

    // close all menu when click outside
    $(window).click(function() {
      closeAll();
    });

    container
      // stop propagation click outside in menu container
      .on("click", function(event) {
        event.stopPropagation();
      })
      // open a menu if is closed
      .on("click", ITEM_SEL + '[aria-expanded="false"]', function(event) {
        if($(this).attr('data-link') == undefined) {
            event.preventDefault();
            var el = $(this);
            var panel = el.next();
            closeAll();
            el.attr("aria-expanded", "true");
            panel.attr("aria-hidden", "false");
            $(CLOSE_SEL).attr("aria-hidden", "false");
        }
      })
      // close all menu when click on an opened menu
      .on("click", ITEM_SEL + '[aria-expanded="true"]', function(event) {
        event.preventDefault();
        closeAll();
      })
      // close all menu when click on close button
      .on("click", CLOSE_SEL, function(event) {
        event.preventDefault();
        closeAll();
      });

  }

  function closeAll() {
    $(ITEM_SEL).attr("aria-expanded","false");
    $(BIGITEM_SEL).attr("aria-hidden", "true");
    $(CLOSE_SEL).attr("aria-hidden", "true");
  }

})(jQuery);
