/**
 * Carousel: with slick carousel library in mode modules
 * http://kenwheeler.github.io/slick/
 */
(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = ".Carousel";
  var TOLLENS_PRODUCT_SEL = ".Product--Tollens";
  var TOLLENS_HP_SEL = ".Row--productSilder";
  var TOLLENS_HPFAQ_SEL = ".Row--faqSlider";
  var TOLLENS_SIDEBAR_SEL = ".Row-sidebar";
  /**
   * Init
   */
  function init() {
    var container = $(CONTAINER_SEL);
    if (container.length === 0) {
      return;
    }
    container.each(function(){
      var responsive_value = $(this).attr('data-carousel');
      var products_tollens = $(this).find($(TOLLENS_PRODUCT_SEL));
      var hp_product_carousel_tollens = $(TOLLENS_HP_SEL);
      var homepage_faq_tollens = $(TOLLENS_HPFAQ_SEL);
      var sidebar_tollens = $(TOLLENS_SIDEBAR_SEL);
      var displayArrows = true;

      //Tollens config for products
      if(products_tollens.length > 0){
        //With sidebar
        if(sidebar_tollens.length > 0){
          responsive_value = [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        }

        //TOLLENS HP product carousel
        else if (hp_product_carousel_tollens.length > 0 && responsive_value == "responsiveProduct" ){
          responsive_value = [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        }
        //without sidebar
        else {
          responsive_value = [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        }
      }
      //TOLLENS HP FAQ carousel
      else if (homepage_faq_tollens.length > 0 && responsive_value == "responsivePush" ){
        responsive_value = [
          {
            breakpoint: 3000,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1
            }
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
      
      //global config
      else {
        if(responsive_value == "responsivePush"){
          responsive_value = [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 5
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        } else if (responsive_value == "responsivePushEmbed") {
          responsive_value = [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 5
              }
            },
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        } else if (responsive_value == "responsiveProduct") {
          responsive_value = [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        } else if (responsive_value == "responsiveProductEmbed") {
          responsive_value = [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 450,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        } else if (responsive_value == "responsivePushListFaq") {
          displayArrows = true;

          responsive_value = [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 450,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        } else if (responsive_value == "responsiveColorSelector") {
          displayArrows = false;
          responsive_value = [
            {
              breakpoint: 4000,
              settings: {
                slidesToShow: 7,
                centerMode: true,
              }
            },
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 7,
                centerMode: true,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 7,
                centerMode: true,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                centerMode: true,
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 3,
                centerMode: false,
              }
            }
          ]
        }
      }
      $(this).slick({
        arrows: displayArrows,
        slidesToShow: 4,
        responsive: responsive_value,
        swipeToSlide: true,
      });
    })

  }
})(jQuery);
