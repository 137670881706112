/**
 * GridManager: wrap grid elements in ezoe RichText
 */

;(function wrapGrid() {

    $(window).ready(init);

    var CONTAINER_SEL = '.RichText';
    var GRID_SEL = '.RichText [class*="GridM-"]';
    var INVERT_SEL = ':not(' + GRID_SEL + ')';
    var BREAK_SEL = CONTAINER_SEL + ' *' + INVERT_SEL; //' *' instead of '> *' to target all grids, not only the 1st level ones
    var SEPARATOR_SEL = GRID_SEL + ' + ' + INVERT_SEL; // to work with the first().prevUntil

    var WRAPPER = '<div class="GridM" />';

    function init() {
        var element = $(GRID_SEL);
        var isFirstSibling = false

        if (element.length === 0) {
            return;
        }

        // check if element has a previous sibling
        if($(element['0']).prev().length === 0) {
          isFirstSibling = true
        }

        var breakpoints = $(BREAK_SEL);
        var separators = $(SEPARATOR_SEL);

        // Get the matching elements after all breakpoints and wrap them
        breakpoints.each(function() {
            $(this).nextUntil(INVERT_SEL).wrapAll(WRAPPER);
        });

        // lists the elements to wrap
        var elementsArray = separators.first().prevUntil(INVERT_SEL).toArray()

        // in the case of first sibling, reverses the order of the array (issue causing the elements to be generated in the reversed order)
        if(isFirstSibling){
          elementsArray = elementsArray.reverse()
        }

        // wraps the elements to dom
        $(elementsArray).wrapAll(WRAPPER);
    }

})(jQuery);
