var Zolpan = Zolpan || {};
Zolpan.hCaptcha = (function ($, self) {

    var isInit = false,
        onApiLoadedDefer = $.Deferred();

    function loadApi() {
        if (isInit) return true;
        $.getScript('https://js.hcaptcha.com/1/api.js?onload=ZolpanHcaptchaLoading');
        isInit = true;
    }

    function onApiLoadedRenderWidget(elementId, recaptchaResponseCallback) {
        var renderWidgetDefer = $.Deferred();
        loadApi();

        onApiLoadedDefer.done(function () {
            var widgetId = hcaptcha.render(elementId, {
                'sitekey': Zolpan.vars.hcaptcha_site_key,
                'size': 'invisible',
                'callback': recaptchaResponseCallback
            });

            renderWidgetDefer.resolve(widgetId);
        });

        return renderWidgetDefer;
    }

    function loading() {
        onApiLoadedDefer.resolve();
    }

    $(document).ready(function () {
        // Not handle AJAX loaded Form
        var $recaptchaButton = $('.h-recaptcha:not(.include_200 .h-recaptcha)');
        if ($recaptchaButton.length > 0) {
            $recaptchaButton.each(function (index, element) {
                var $element = $(element);
                var $form = $element.closest('form');

                var $recaptchWidgetContainer = $('<div></div>', {id: "hcaptcha_" + index});
                $form.append($recaptchWidgetContainer);

                var onHcaptchaTokenCallback = function (token) {
                    $form.find('input[id$="hcaptcha"]').val(token);
                    $form.submit();
                }

                var onWidgetRenderCallback = function (widgetId) {
                  $element.on('click', function (e) {
                        e.preventDefault();
                        hcaptcha.execute(widgetId);
                    });
                }

                onApiLoadedRenderWidget($recaptchWidgetContainer.attr('id'), onHcaptchaTokenCallback).done(onWidgetRenderCallback);
            })
        }
    });

    return $.extend(self, {
        init: loadApi,
        loading: loading,
        onLoadRender: onApiLoadedRenderWidget
    });
})(jQuery, {});
ZolpanHcaptchaLoading = Zolpan.hCaptcha.loading;
