/**
 * To zoom on rich text images
 * 
 * custom tag a ajouter sur l'image
 *  + test sur class relative
 * 
 */
 (function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = "[data-url-zoom]";
  var MODAL = $(".modal");
  var MODAL_CONTENT = $(".modal .modal-content");

  /**
   * Init
   */
  function init() {

    //if container isn't here : return.
    var container = $(CONTAINER_SEL);
    if (container.length === 0) {
      return;
    }

    //add class to modal no not mess with existing CSS
    MODAL.addClass('ModalRichText');

    $(CONTAINER_SEL).click(function() {
      var dataUrl = $(this).attr('data-url-zoom')

      //gen class on modal
      MODAL.addClass('modal-visible');
            
      //add image into modal
      MODAL_CONTENT.append('<img src="' + dataUrl + '" alt=""/>');

      //listen to close modal btn & empty html content
      $('.modal-close').click(function(e) {
        MODAL.removeClass('modal-visible');
        MODAL_CONTENT.empty();
      });
    })
  }

})(jQuery);
