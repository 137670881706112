var Zolpan = Zolpan || {};

Zolpan.simulator = (function ($, ns) {

    var REGISTER_FORM_MISTAKES_SELECTOR = '#register .Form-mistakes';

    ns.init = function () {
        //ns.iframeResize();
        ns.submitFormLimited();
        ns.submitFormCodeLimited();

        // Create IE + others compatible event handler
        var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

        $('.Simulator-btn').on('click', function (e) {
            if ($('.Simulator-content').attr('data-state') === 'close') {
                var source = document.getElementById('iframe').contentWindow;
                ns.postReceived(source, 'https://simulator.peintures.pro', '', {'action': 'reopen', 'status': '1'}, '1');
            }
        });

        eventer(messageEvent, function (e) {
          if(e.origin == "https://simulator.peintures.pro") {
            var data = e.data;
            var result = null;
            var params = {};


            if (e.origin === 'http://s7.addthis.com') {
                return;
            }

            try {
                if (typeof data !== 'undefined') {
                    var dataJson = JSON.parse(data);
                }
                //console.log('Received object json : ', data);

                //console.log('Received object json data action: ', dataJson.action);

                //console.log('Received object json data origin: ', e.origin);

                ns.postReceived(e.source, e.origin, dataJson.action, 'postMessageReceived');

                ns.ajaxLoginCheck(e.source, e.origin);

                if (Zolpan.simulator.api.hasOwnProperty(dataJson.action)) {
                    params = Zolpan.simulator.api[dataJson.action](dataJson);
                }

                if (params == null || jQuery.isEmptyObject(params)) {
                    console.log('No action defined');
                    return;
                }
                ns.ajaxPost(params[0], params[1], params[2], e.source, e.origin);
            } catch (err) {
                console.log('Received string client : ' + data);
                console.log('err.message client : ' + err.message);
            }

          }
        });

        var $btn = $('#postmessage');
        $btn.on('click', function (e) {
            e.preventDefault();

            //alert('click postmessage');

            var data = $('#senddata').val();
            var id = $('#senddataid').val();

            var iframe = $('#iframe').get(0).contentWindow;

            iframe.postMessage(JSON.stringify({'data': data, 'id': id}), '*');
        });

        ns.ajaxLoginCheck = function (source, origin) {
            $('#ajaxLoginCheckSimulateur').off('submit').on('submit', function (e) {
                e.stopPropagation();
                e.preventDefault();
                $('.Form-mistake.form-mistake-login').hide();

                var form_data = $(this).serialize();
                $.ajax({
                    url: '/login_check',
                    data: form_data,
                    type: 'POST',
                    success: function (response) {
                        if (response.success != false) {
                            params = Zolpan.simulator.api['ready']();
                            ns.ajaxPost(params[0], params[1], params[2], source, origin, function () {
                                $('#simulator_action_buttons .Simulator-btn').not('.Simulator-btn-close').remove();
                                $('#simulator_action_buttons .TopMenu-text').text(this.greeting).show();

                                var modal = new VanillaModal.default();
                                modal.close('#connectModal');

                                $('.modal').hide();
                            });
                        } else {
                            $('.Form-mistake.form-mistake-login').show()
                        }
                    }
                });
                return false;

            });
        };


    };

    ns.postReceived = function (source, origin, action, data, status) {
        //console.log('in postReceived before : ' + action + ' | ' + origin);
        switch (data) {
            case 'postMessageReceived' :
                source.postMessage(JSON.stringify({'action': 'postMessageReceived', 'postMessageActionReceived': action, 'status': '1'}), origin);
                break;
            default :
                source.postMessage(JSON.stringify(data), origin);
                break;
        }

        //console.log('in postReceived after data : ' + JSON.stringify(data));
    };

    ns.ajaxPost = function (requestType, wsUrl, dataPost, source, origin, callback) {
        $.ajax({
            url: '/simulator' + wsUrl,
            dataType: 'json',
            type: requestType,
            data: dataPost,
            success: function (result) {
                ns.postReceived(source, origin, null, result);

                if (callback != undefined) {
                    //console.log('result >>> ', result);
                    callback.call(result);
                }
            }
        });
    };

    ns.iframeResize = function () {
        var iFrameID = document.getElementById('iframe');
        if (iFrameID) {
            iFrameID.height = '';
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + 'px';
        }
    };

    ns.submitFormLimited = function () {
        $('form[name="account_register_limited_form"]').on('submit', function (e) {

            e.stopPropagation();
            e.preventDefault();

            var form_data = $(this).serialize();

            var zolpan_account_register_limited = Routing.generate(
                'zolpan_account_register_limited'
            );

            $.ajax({
                url: zolpan_account_register_limited,
                data: form_data,
                dataType: 'json',
                type: 'POST',
                success: function (response) {
                    if (response.success === 'OK') {
                        //ns.getLivraison();
                        $(REGISTER_FORM_MISTAKES_SELECTOR).children().remove();
                        $('div[name="div_register_form_limited"]').hide();

                        // CHECKCODE PART
                        $('#div_register_form_limited_checkcode').show();
                        $('#zolpan_account_register_limited_userid').val(response.params.userid);
                    } else {
                        if ($(REGISTER_FORM_MISTAKES_SELECTOR).length === 0)
                            $('<ul class="Form-mistakes"></ul>').prependTo('#register');
                        $(REGISTER_FORM_MISTAKES_SELECTOR).children().remove();
                        for (var i in response.errors)
                            $('<li class="Form-mistake form-mistake-login">' + response.errors[i] + '</li>')
                                .prependTo(REGISTER_FORM_MISTAKES_SELECTOR);
                    }
                }
            });

            return false;
        });
    };

    ns.submitFormCodeLimited = function () {
        $('form[name="zolpan_account_register_limited_checkcode"]').on('submit', function (e) {
            e.stopPropagation();
            e.preventDefault();

            var form_data = $(this).serialize();
            var account_register_limited_validate = Routing.generate(
                'zolpan_account_register_limited_validate'
            );

            $.ajax({
                url: account_register_limited_validate,
                data: form_data,
                dataType: 'json',
                type: 'POST',
                success: function (response) {
                    if (response.success === 'OK') {
                        var iframe = $('#iframe').get(0).contentWindow;
                        var params = Zolpan.simulator.api['ready']();
                        ns.ajaxPost(params[0], params[1], params[2], iframe,
                            window.location.protocol + '//simulateur-cdo.pulsation.fr',
                            function () {
                                $('#simulator_action_buttons .Simulator-btn').not('.Simulator-btn-close').remove();
                                $('#simulator_action_buttons .TopMenu-text').text(this.greeting).show();
                                $('.modal').hide();
                            });
                    } else {
                        if ($(REGISTER_FORM_MISTAKES_SELECTOR).length === 0)
                            $('<ul class="Form-mistakes"></ul>').prependTo('#register');
                        $(REGISTER_FORM_MISTAKES_SELECTOR).children().remove();
                        for (var i in response.errors)
                            $('<li class="Form-mistake form-mistake-login">' + response.errors[i] + '</li>')
                                .prependTo(REGISTER_FORM_MISTAKES_SELECTOR);
                    }
                }
            });

            return false;
        });
    };

    return ns;
})(jQuery, {});

Zolpan.simulator.init();
