/**
 * Facets Tollens
 */
 (function ($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var TOLLENS_SEL = ".ColorsShop-Tollens"
  var CLEARBUTTON = '.js-clear-filters-btn';

  /**
   * Init
   */
  function init() {
    var tollens = $(TOLLENS_SEL);
    if (tollens.length === 0) {
      return;
    }
    displayFilterButton();
  }

  //Checks if current page URL has search or collection parameters
  function displayFilterButton(){
    if(window.location.href.indexOf("search") != -1 || window.location.href.indexOf("collection") != -1){
      $(CLEARBUTTON).show();
    } else {
      $(CLEARBUTTON).hide();
    }
  }

})(jQuery);