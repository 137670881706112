/**
 * scrollspy: indicator page scrolling in anchor nav
 */
(function ($) {
  "use strict";

  $(window).on("load",init);

  // Constants
  var SPY_SEL = '[data-scrollspy="nav"]';
  var FIRSTNAVLI = '.Nav-list li:nth-child(1) .Nav-item';
  var SPYITEM_SEL = '.Nav-item';
  var SPYITEMFAQ_SEL = '.Nav-itemTitle';
  var SECTIONS_SEL = '[data-scrollspy="sections"]';
  var SECTION_SEL = '[data-scrollspy="section"]';
  var ARTICLE_SEL = '.Row-mainContent';
  var SIDEBARACTIVE_SEL = '.sidebar-active';
  var SIDEBARCONTAINER_SEL = '.Row-sidebarContainer';
  var BREADCRUMBSLAST_SEL = '.Breadcrumbs li:last-child span';
  var SECTIONTITLES = [];
  var SECTIONDATA = [];
  var W = $(window);
  var RESIZE_WIDTH = 1900; //resize start point

  /**
   * Init
   */
  function init() {
    if ($(SIDEBARACTIVE_SEL).length === 0) {
      return;
    }
    
    updateArticlePadding();
    tagSections();
    clearSections();
    createSummary();
    faqActive();
    animPercent();
    $(window).on('scroll', animPercent);

    //fn on window width change
    var width = $(window).width();
    $(window).on('resize', function() {
      if ($(this).width() !== width) {
        width = $(this).width();
        updateArticlePadding();
      }
    });
  }

  /**
   * Update the article.Row-mainContent padding-left based on the window width.
  */
   function updateArticlePadding(){
    if($(SIDEBARACTIVE_SEL).length > 0) {
      var article = $(ARTICLE_SEL);
      //if window is desktop + if window < 1900 (size where the nav goes over the article) and > 1210 (breakpoint content is full width)
      if ($(window).width() < RESIZE_WIDTH && $(window).width() > 1210){
        var paddingLeft = (RESIZE_WIDTH - $(window).width())/2 + 20; //20 = base padding on desktop size 
        paddingLeft = paddingLeft + "px";
        article.css("padding-left", paddingLeft);
      } 
      //for full width content breakpoint and lower
      else if ($(window).width() < RESIZE_WIDTH && $(window).width() > 1024){
        var paddingLeft = $(SIDEBARCONTAINER_SEL).width() + 20;
        paddingLeft = paddingLeft + "px";
        article.css("padding-left", paddingLeft);
      } else {
        article.css("padding-left", "");
      }
    }
  }


  function tagSections() {
    var sections = $(SECTIONS_SEL);
    var section = sections.find('> h2'); //'> h2' instead of 'h2' to target only the 1st level ones

    section.each(function(index){
      var $set = $(this).nextUntil("h2").addBack();
      $set.wrapAll('<div id="section' + index + '" data-scrollspy="section" />');
      SECTIONTITLES.push($(this).text())
    })

    // Create the table of values for each section
    $(SECTION_SEL).each(function(index) {
      var item = $(this)
      SECTIONDATA.push({
        index: index,
        title: item.find('> h2').text(), //'> h2' instead of 'h2' to target only the 1st level ones
        offset: item.offset().top,
        height: item.height()
      });
    });
  }

  //add empty div to clear each section
  function clearSections(){
    $(SECTION_SEL).append('<div class="scrollspy-clear"></div>');
  }

  function createSummary() {
    if(SECTIONDATA.length >= 2 ) {
      var menuContainer = document.createElement('ul');
      //  Create the menu for each section
      SECTIONDATA.forEach(function (item, index) {
        var menuItem = '<li>' +
          '<a class="Nav-item active"' +
          'href="#section' + index + '"' +
          'data-scrollspy-start="' + item.offset + '"' +
          'data-scrollspy-height="' + item.height + '">' +
          item.title +
          '<span class="Nav-scroller" aria-hidden></span>' +
          '</a>' +
          '</li>';
        $(menuContainer).append(menuItem).addClass('Nav-list');
      });
      $(SPY_SEL).append(menuContainer).show();
    } else {
      $(".Row-sidebarTitle").hide();
    }
  }

  function faqActive(){
    var breadcrumbsLast = $(BREADCRUMBSLAST_SEL);
    var items = $(SPYITEMFAQ_SEL);
    items.each(function (index, item) {
      var item = $(item);
      if($.trim(item.html()) === $.trim(breadcrumbsLast.html())){
        item.parent().closest('a').addClass('faq-active');
      }
    });
  }

  function animPercent() {

    if(SECTIONDATA.length >= 2 ) {
      var currentScrollTop = W.scrollTop() + 320; //fixed header height + margins.
      var items = $(SPYITEM_SEL);
      var firstNavLi = $(FIRSTNAVLI);
      items.each(function (index, item) {
        var item = $(item);
        var sectionStart = item.data('scrollspy-start');
        var sectionHeight = item.data('scrollspy-height');
        var sectionEnd = sectionStart + sectionHeight;

        // If item is first child, scroll before section start
        if( item.is(firstNavLi) && currentScrollTop < sectionStart && item.hasClass('active') === false){
          item.addClass('active');
        }
        // Scroll after section start and before section end (on section)
        else if (currentScrollTop >= sectionStart && currentScrollTop <= sectionEnd && item.hasClass('active') === false) {
          item.addClass('active');
        }
        // Scroll after section end
        else if (currentScrollTop > sectionEnd && item.hasClass('active') === true) {
          item.removeClass('active');
        }
        // Scroll before section start
        if (currentScrollTop < sectionStart && item.hasClass('active') === true){
          // In case of first-child
          if(item.is(firstNavLi)){
            return;
          } else {
            item.removeClass('active');
          }
        }
      })
    }
  }
})(jQuery);
