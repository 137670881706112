/**
 * Toggle: toggle a block linked by id
 */
(function ($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var BTN_SEL = "[data-toggle]";
  var MOBILE_SEL = "[data-toggle-mobile]";

  /**
   * Init
   */
  function init() {

    // no test if BTN_SEL exists because we need on ajax loading elements
    $('body').on("click", BTN_SEL, function () {
      var el = $(this);
      var panel = $("#" + el.attr("aria-controls"));
      el.attr(
        "aria-expanded",
        el.attr("aria-expanded") == "true" ? "false" : "true"
      );
      panel.attr(
        "aria-hidden",
        panel.attr("aria-hidden") == "true" ? "false" : "true"
      );
    });

    var mobileBtn = $(MOBILE_SEL);
    if (mobileBtn.length === 0) {
      return;
    } else {
      if (window.matchMedia("(max-width: 1024px)").matches) {
        var panel = $("#" + mobileBtn.attr("aria-controls"));
        mobileBtn.attr("aria-expanded", 'false');
        panel.attr("aria-hidden", "true");
      }
    }

  }
})(jQuery);
