var Zolpan = Zolpan || {};

Zolpan.analytics = function ($) {

    function init() {
        trackDownloadedPDF();
    }

    function trackDownloadedPDF() {
        $("a[href$='.pdf']").on('click', function () {
            var $linkNode = $(this).closest('a');
            var documentLabel = $linkNode.data('documentname');

            if (documentLabel === undefined) {
                documentLabel = $linkNode.attr('href');
                documentLabel = documentLabel.substring(documentLabel.length - documentLabel.lastIndexOf('/') + 1);
            }

            ga('send', 'event', {
                eventCategory: 'Téléchargement PDF',
                eventAction: 'click',
                eventLabel: documentLabel
            });
        });
    }

    $(document).ready(init);

}(jQuery);
