/**
 * Minify header on scroll
 */
;(function ($) {
  "use strict"

  $(window).ready(init)

  // Constants
  var CONTAINER_SEL = ".Header"

  /**
   * Init
   */
  function init() {
    var container = $(CONTAINER_SEL)
    if (container.length === 0) {
      return
    }

    var top = container.height()

    $(window).on("scroll", function () {
      if ($(this).scrollTop() >= top) {
        container.attr("data-state", "minify")
      } else {
        container.attr("data-state", "")
      }
    })
  }
})(jQuery)
