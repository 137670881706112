Zolpan.gds = (function ($) {

  if($('.js-gds').length > 0) {

    var stepChoicesId = [];
    var stepChoicesName = [];

    // On click on a button
    $(document).on('click', '.FormSteps-response input', function () {
      var formStep = $(this).closest('.FormSteps-step');
      var stepNumber = formStep.attr('data-step');
      var value = $(this).val();

      var urlParts = window.location.pathname.split('/');
      var stepsUrl = urlParts.splice(2);

      // Change url, add the lastest choice on it
      var actualUrl = window.location.href;
      window.history.pushState("Guide des Solutions", "Guide des Solutions", actualUrl + "/" + value);

      // Get text in the label
      var name = $(this).next().text();

      // Save choice in stepChoices
      stepChoicesId[stepNumber + ""] = value;
      stepChoicesName[stepNumber + ""] = name;

      // If step 1 - 7 Load the next question and validate this one
      if (stepNumber < 7) {
        $.get({
          url: Routing.generate('ajax_load_step') + "/" + stepNumber + "/" + value,
          success: function (data) {

            // Valid the current input (remove current class and add valid class)
            formStep.removeClass('FormSteps-step--current').addClass('FormSteps-step--valid');
            formStep.find('.FormSteps-result').text(name);

            var newStepNumber = data.step;
            var newChoices = data.choices;
            var newFormStep = $(".FormSteps-step[data-step='" + newStepNumber + "']");

            // Activate the new input (remove disabled class and add current class)
            newFormStep.removeClass('FormSteps-step--disabled').addClass('FormSteps-step--current');

            addChoiceStep(newFormStep, newChoices, newStepNumber);

            scrollTo(newFormStep);

          }
        });
        // If it's the 7th question, get the systems and text "Travaux préparatoires"
      } else if (stepNumber == 7) {
        $.get({
          url: Routing.generate('ajax_load_step') + "/" + stepNumber + "/" + JSON.stringify(stepChoicesId),
          success: function (data) {
            // Valid the current input (remove current class and add valid class)
            formStep.removeClass('FormSteps-step--current').addClass('FormSteps-step--valid');
            formStep.find('.FormSteps-result').text(name);

            $('#js-travauxText').html(data.travauxText);

            showSystems(data.systemes);

            $('.js-gds-result').removeClass('hidden');

            scrollTo($('.js-gds-result'));

            var dataResult = {
              'event': 'eventGA',
              'eventCategory': 'Guide des solutions',
              'eventAction': 'Résultats',
              'eventLabel': window.location.pathname
            }

            dataLayer.push(dataResult);
          }
        });
      }

    });

    // Add button under the question
    function addChoiceStep(FormStep, Choices, stepNumber) {
      var checkboxList = FormStep.find('.FormSteps-response.FormCheckBox-list');
      var index = 1;

      $.each(Choices, function (key, value) {
        var choiceHtml;
        choiceHtml = "<p class='FormCheckBox-item'>";
        choiceHtml += "<input id='question-" + stepNumber + "-" + index + "' type='radio' name='question-" + stepNumber + "' value='" + value.id + "'/>";
        choiceHtml += "<label for='question-" + stepNumber + "-" + index + "'>" + setFirstLetterUppercase(value.name) + "</label>";
        choiceHtml += "</p>";

        checkboxList.append(choiceHtml);

        index += 1;
      });
    }

    // Add systems on the result section
    function showSystems(systems) {
      $('#js-systemsList').empty();
      $.each(systems, function (key, value) {
        var systemsList = "";
        systemsList = "<h3>" + value.name + "</h3>";

        $.each(value.products, function (key, prd) {
          if(prd.url != undefined) {
            systemsList += "<p><a href='"+prd.url+"'>" + prd.name + "</a> : " + prd.infos + "</a></p>";
          } else {
            systemsList += "<p>" + prd.name + "</a> : " + prd.infos + "</p>";
          }
        });
        $('#js-systemsList').html($('#js-systemsList').html() + systemsList);
      });
    }

    // On click on "Modifier" button
    $(document).on('click', '.FormSteps-modify', function () {
      var formStep = $(this).closest('.FormSteps-step');
      var stepNumber = formStep.attr('data-step');

      $('.js-gds-result').addClass('hidden');

      // If button are not already loaded under the question (not loaded if from direct url)
      if (formStep.find('.FormSteps-response.FormCheckBox-list').text().trim() == "") {
        var urlParts = window.location.pathname.split('/');

        var steps = urlParts.splice(2);
        var previousStep = stepNumber - 1;

        $.get({
          url: Routing.generate('ajax_load_step') + "/" + previousStep + "/" + steps[stepNumber - 2],
          success: function (data) {
            var newStepNumber = data.step;
            var newChoices = data.choices;
            var newFormStep = $(".FormSteps-step[data-step='" + newStepNumber + "']");

            addChoiceStep(newFormStep, newChoices, newStepNumber);

            scrollTo(newFormStep);
          }
        });

      }
      // Change url to remove following parameters (they will change depending on the new choice)
      var stepsToKeepUrl = window.location.pathname.split('/').splice(0, parseInt(stepNumber) + 1);
      window.history.pushState("Guide des Solutions", "Guide des Solutions", window.location.protocol + "//" + window.location.host + stepsToKeepUrl.join('/'));

      var offset = parseInt(stepNumber) + 1;

      // Disable all questions after the one the user choose to edit
      for (var i = offset; i <= 7; i++) {
        var stepToDisable = $(".FormSteps-step[data-step='" + i + "']");
        stepToDisable.removeClass('FormSteps-step--current').removeClass('FormSteps-step--valid').addClass('FormSteps-step--disabled');
        stepToDisable.find('.FormSteps-response.FormCheckBox-list').html("");
      }

      formStep.removeClass('FormSteps-step--valid').addClass('FormSteps-step--current');
    });

    $(document).ready(function () {
      var urlParts = window.location.pathname.split('/');
      var steps = urlParts.splice(2);
      var offset = [""];
      stepChoicesId = offset.concat(steps);

      // If coming from direct url, need to load previous responses
      if (steps.length > 0) {
        // Ajax to retrieve names for selected
        $.get({
          url: Routing.generate('ajax_get_labels_gds') + "/" + steps,
          success: function (data) {
            for (var i = 0; i < data.length; i++) {
              var stepNumber = i + 1;
              var stepToSetLabel = $(".FormSteps-step[data-step='" + stepNumber + "']");

              stepToSetLabel.find('.FormSteps-result').html(setFirstLetterUppercase(data[i]));
            }
          }
        });

        if (steps.length == 7) {
          $.get({
            url: Routing.generate('ajax_load_step') + "/7/" + JSON.stringify(stepChoicesId),
            success: function (data) {
              $('#js-travauxText').html(data.travauxText);

              showSystems(data.systemes);

              $('.js-gds-result').removeClass('hidden');

              scrollTo($('.js-gds-result'));

              var dataResult = {
                'event': 'eventGA',
                'eventCategory': 'Guide des solutions',
                'eventAction': 'Résultats',
                'eventLabel': window.location.pathname
              }

              dataLayer.push(dataResult);
            }
          });
        }
      }
    });

    function setFirstLetterUppercase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function scrollTo(elem) {
      $('html,body').animate({
        scrollTop: elem.offset().top - $('.Header').outerHeight()
      }, 1000);
    }
  }

})(jQuery);
