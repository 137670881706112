Zolpan.documents = (function ($) {

    // Initialization
    function init() {
        initDocumentHoverListener();
    }

    function initDocumentHoverListener() {
        Translator.locale = $('html').attr('lang');

        $('.Teaser-img[href*=".pdf"]').hover(function() {
            $(this).attr('data-content', Translator.trans('document.download'));
        })
    }

    $(document).ready(function () {
        init();
    });
})(jQuery);
Zolpan.documents = Zolpan.documents || {};