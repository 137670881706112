/**
 * Dropdown: toggle a block linked by id and close other dropdown
 */
;(function ($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var BTN_SEL = "[data-hide-btn]";
  var CONTAINER_SEL = "[data-hide]";

  /**
   * Init
   */
  function init() {
    var element = $(BTN_SEL);
    if (element.length === 0) {
      return;
    }

    // show on page loading
    $(BTN_SEL).attr("aria-expanded", "true");
    $(CONTAINER_SEL).attr("aria-hidden", "false");

    $(BTN_SEL).on("click", function (event) {
      if ($(BTN_SEL).closest($(CONTAINER_SEL))) {
        close();
        event.stopPropagation();
      }
    });

    function close() {
      var current_btn = $(BTN_SEL + '[aria-expanded="true"]');
      var current_dropdown = $(CONTAINER_SEL + '[aria-hidden="false"]');
      current_btn.attr("aria-expanded", "false");
      current_dropdown.attr({ "aria-hidden": "true", hidden: true });
      current_dropdown.find(BTN_SEL).attr("aria-expanded", "false");
    }
  }
})(jQuery);
