$(document).ready(function() {

    $( document ).on('hinclude.load', function(event, req) {
        disabledLoading($( req ));
        searchResultCount();
    });

    setInterval(function() {
        disabledLoading($('body'));
        disabledEmptySection();
    }, 1000);

});

function disabledLoading(element) {
    var loading = element.parent().find(".Loader");
    loading.remove();
    if (element.children().length == 0) {
        element.closest('section').remove();
    }
}

function disabledEmptySection() {
    $.each($('.include_200'), function(index, value) {
        if ($(value).children().length == 0) {
            $(value).closest('section').remove();
        }
    });
}

function searchResultCount() {
    Translator.locale = $('html').attr('lang');

    // Count total
    var total_count = 0;
    $(".Result-count").each(function (index) {
        total_count += parseInt($( this).val());
    });
    if(total_count > 0) {
        $(".no-results").addClass('hidden');
        $(".results").removeClass('hidden');
        $(".results").html(total_count + " " + Translator.trans('search.results'));
    }
    else {
        $(".results").addClass('hidden');
        $(".no-results").removeClass('hidden');
    }
}
