/**
 * Social network
 */
(function($) {
  "use strict";

  $(window).ready(init);

  //varants
  var CONTAINER_SEL = '[data-social]';
  var BUTTON = '[data-socialbtn]';
  var CLOSE_BUTTON = '[data-socialclose]';
  var COPY_BUTTON = '[data-sociallinkshare]';
  var ALERT_SEL = '[data-social-alert]';


  /**
   * Init
   */
  function init() {
    if ($(CONTAINER_SEL).length === 0) return;
    
    $(BUTTON).on('click', function(e) {
      openSocial()
    })

    $(CLOSE_BUTTON).on('click', function(e) {
      closeSocial()
    })

    // Copy URL to clipboard.
    $(COPY_BUTTON).on('click', function() {
      var currentUrl = document.createElement('textarea');
      document.body.appendChild(currentUrl);
      currentUrl.value = window.location.href;
      currentUrl.select();
      document.execCommand('copy');
      document.body.removeChild(currentUrl);
      $(ALERT_SEL).attr('hidden', false)
      var removeAlert = function(){
        $(ALERT_SEL).attr('hidden', true)
      };
      setTimeout(removeAlert, 1500);
    });

  }

  function openSocial() {
    closeSocial()
    $(CONTAINER_SEL).attr('aria-hidden', 'false')
    $(BUTTON).attr('aria-hidden', 'true')
  }

  function closeSocial() {
    $(CONTAINER_SEL).attr('aria-hidden', 'true')
    $(BUTTON).attr('aria-hidden', 'false')
  }

})(jQuery);
