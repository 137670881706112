/**
 * ProductSlider: with slick carousel library in mode modules
 * http://kenwheeler.github.io/slick/
 */
(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = ".ProductSlider";
  var NAV_SEL = ".ProductSlider-nav";

  /**
   * Init
   */
  function init() {
    var container = $(CONTAINER_SEL);
    var navSlider = $(NAV_SEL);
    if (container.length === 0) {
      return;
    }
    container.slick({
      dots: false,
      arrows: true
    });
    navSlider.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: CONTAINER_SEL,
      centerMode: false,
      dots: false,
      arrows: false,
      focusOnSelect: true
    });
  }
})(jQuery);
