/**
 * Load More button
 */
(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var BTN_SEL = "[data-load-more]";
  var CONTAINER_SEL = "[data-load-more-container]";
  var COLORS_CONFIGURATOR = ".Row-sidebarSticky"
  var COLORS_CONTAINER = "#ColorChart-container"

  /**
   * Init
   */
  function init() {
    var button = $(BTN_SEL);
    if (button.length === 0) {
      return;
    }

    var separator = '/';
    var offset = 0;
    var step = parseInt(button.attr('data-load-more-offset'));
    var route = button.attr('data-load-more-route');
    var category = '';
    var location = '';
    var locationId = '';
    var searchText = '';
    var catalog = '';
    var getparams = '';

    // For job offers
    var jobFilter = {};
    if(button.attr('data-load-more-contract-type-job') || button.attr('data-load-more-city-job') || button.attr('data-load-more-function-job')) {
      jobFilter = {
          'contract_type':button.attr('data-load-more-contract-type-job'),
          'city':button.attr('data-load-more-city-job'),
          'function':button.attr('data-load-more-function-job')}
    }
    // End job offers

    if(button.attr('data-load-more-category')) {
      category = '/' + (getParam('categorie') ? getParam('categorie'): 0);
    }
    if(button.attr('data-load-more-location')) {
      location = '/' + button.attr('data-load-more-location');
    }
    if(button.attr('data-load-more-locationId')) {
      locationId = '/' + button.attr('data-load-more-locationId');
    }
    if(button.attr('data-load-more-searchText')) {
      searchText = '' + button.attr('data-load-more-searchText');
    }
    if(button.attr('data-load-more-getparams')) {
      if(button.attr('data-load-more-getparams') == 'true') {
        getparams = window.location.search;
      }
    }
    if(button.attr('data-load-more-catalog')) {
      separator = '';
      catalog = '?catalog=' + button.attr('data-load-more-catalog') + '&offset=';
    }

    $('body').on("click", BTN_SEL, function() {
      offset = offset + step;
      $.get({
        url: Routing.generate(route) + location + catalog + separator + offset + category + locationId + searchText + getparams,
        data: jobFilter,
        success: function (data) {
          $(CONTAINER_SEL).append(data);
          var nbItems = $(CONTAINER_SEL + ' > *').length;
          if (nbItems % offset != 0 && (nbItems - step) % offset != 0) {
            button.hide();
          }
          if ($(COLORS_CONFIGURATOR) && $(COLORS_CONTAINER)) $(COLORS_CONFIGURATOR).attr('data-sticky-state', 'top')
        }
      });
    });

    function getParam(sParam) {
      var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
      }
    }

  }
})(jQuery);
