/**
 * Justified gallery init
 */
 (function ($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var TOLLENS_SEL = ".Gallery-tollens"
  var GALLERY_SEL = "[data-gallery]";
  var LOADMORE_SEL = "[data-gallery-loadmore]";
  var CLEARBUTTON = '.js-clear-filters-btn';
  var FILTERGROUP = '.Facets-list';
  var FILTERSELECTED = '.Facets-item--selected';
  var FACET_SEL = "[data-gallery-facet]";
  var STATE_SEL = "[data-state]";

  /**
   * Init
   */
  function init() {

    var tollens = $(TOLLENS_SEL);
    if (tollens.length === 0) {
      return;
    }

    var gallery = $(GALLERY_SEL);
    gallery.justifiedGallery({
      rowHeight: 320,
      margins: 30
    });

    infiniteScroll();
    filterGalery();
    clearFilterButton();
    //listen to ajax calls and start FN once ajax call is complete
    $(document).ajaxComplete(function() {
      openFilterDropdown();
      displayFilterButton();
    });
  }

  function infiniteScroll() {
    var gallery = $(GALLERY_SEL);
    var offset = parseInt($("#offset_inspiration").val());
    var limit = parseInt($("#limit_inspiration").val());
    var total = parseInt($("#total_inspiration").val());

    $(document).off("click", LOADMORE_SEL);
    $(document).on("click", LOADMORE_SEL, function () {
      // Ajas call 10 element suivant
      var list_tag = $('.Facets-items-galery.Facets-item--selected');
      var tags = [];
      list_tag.each(function (index) {
        if ($(this).attr("href"))
          tags.push($(this).attr("href"));
      });

      var params = new Object();
      params["offset"] = offset;
      params["limit"] = limit;
      if (tags.length > 0)
        params["tags_search"] = tags;

      var zolpan_front_more_inspirations = Routing.generate(
        'zolpan_front_more_inspirations',
        params
      );
      $.ajax({
        url: zolpan_front_more_inspirations,
        success: function (response) {
          gallery.append(response);
          gallery.justifiedGallery('norewind');
          offset = offset + limit;
          if (offset >= total) {
            $(LOADMORE_SEL).hide();
          }
        }
      });
    });
  }

  function filterGalery() {

    $(document).off('click', '.Facets-items-galery.Facets-item--selected');
    $(document).off('click', '.Facets-items-galery:not(.Facets-item--selected)');
    $(document).on('click', '.Facets-items-galery.Facets-item--selected', function (e) {
      e.preventDefault();
      $(this).removeClass('Facets-item--selected');
      callFilterGalery();
    });
    $(document).on('click', '.Facets-items-galery:not(.Facets-item--selected)', function (e) {
      e.preventDefault();
      callFilterGalery($(this));
    });
  }

  function callFilterGalery(selection) {
    var gallery = $('.js-section-Gallery');
    var array_tags_search = new Array();
    var tags_selected;
    $('.Facets-item--selected').each(function () {
      tags_selected = $(this).attr("data-filter");
      if (tags_selected)
        array_tags_search.push(tags_selected);
    });
    if (selection) {
      var selectionId = selection.attr("data-filter");
      if (selectionId)
        array_tags_search.push(selectionId);
    }
    var data = {tags_search: array_tags_search}

    // ajax call
    var zolpan_filter_inspirations = Routing.generate(
      'zolpan_filter_inspirations'
    );
    $.ajax({
      url: zolpan_filter_inspirations,
      data: data,
      type: 'GET',
      success: function (response) {
        gallery.html(response);
        init();
      }
    });
  }

  //clear filter FN
  function clearFilterButton(){
    $(CLEARBUTTON).on('click', function(){
      var gallery = $('.js-section-Gallery');
      // ajax call
      var zolpan_filter_inspirations = Routing.generate(
        'zolpan_filter_inspirations'
      );
      $.ajax({
        url: zolpan_filter_inspirations,
        type: 'GET',
        success: function (response) {
          gallery.html(response);
          init();
        }
      });
    });
  }

  //Display clear button
  function displayFilterButton(){
    if($(FILTERSELECTED).length > 0) {
      $(CLEARBUTTON).show();
    } else {
      $(CLEARBUTTON).hide();
    }
  }

  //Open dropdowns
  function openFilterDropdown(){
    //For each filter group
    $(FILTERGROUP).each(function(){
      var activeFilter = $(this).find('.Facets-item--selected');
      //If filters are selected in group
      if(activeFilter.length > 0){
        //Set aria-hidden on dropdown
        $(this).attr("aria-hidden","false");
        //Set aria-expanded on dropdown h2 (for + / - )
        $(this).siblings().attr("aria-expanded","true");
      }
    });
  }

})(jQuery);
