/**
 *  Default Google place_autocomplete init
 */

var Cromology = Cromology || {};
Cromology.place_autocomplete = (function ($, self) {
  var isInit = false,
    defer = $.Deferred();
  function init() {
    if (isInit) return true;
    $.getScript('https://maps.googleapis.com/maps/api/js?libraries=places&callback=CromologyMapsInit&key=' + Zolpan.vars.google_api_key);
    isInit = true;
  }
  function onLoad(callback) {
    init();
    defer.done(callback);
  }
  function loading() {
    defer.resolve();
  }
  return $.extend(self, {
    init: init,
    loading: loading,
    onLoad: onLoad,
  });
})(jQuery, {});
CromologyPlaceAutocompleteInit = Cromology.place_autocomplete.loading;

(function ($) {
  'use strict';

  $(window).ready(init);

  // Constants
  var WIDGET = '[data-storelocator';
  var WIDGET_SEARCH_SEL = '[data-storelocator-search]';
  var WIDGET_GEOLOCATE = '[data-storelocator-geolocate]';

  /**
   * Init
   */
  function init() {
    var widget = $(WIDGET);
    if (widget.length === 0) {
      return;
    }
    Cromology.maps.onLoad(function(){
      initializeSearchBox();
    });
  }

  // Instantiate search box
  function initializeSearchBox() {
    var input = $(WIDGET_SEARCH_SEL);
    var restrictions = $.map(input.data('storelocator-restriction'), function (value) {
      return [value];
    });
    var options = {
      componentRestrictions: {
        country: restrictions
      }
    }
    new google.maps.places.Autocomplete(input[0], options);
    $(WIDGET_GEOLOCATE).on('click', function() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(data) {
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode( {'address': data.coords.latitude +","+ data.coords.longitude}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              input.val(results[0].formatted_address);
            }
          });
        });
      }
    });
    $(WIDGET).on('submit', function(event) {
      event.preventDefault()
      var value = input.val();
      var route = input.data('storelocator-url');
      document.location.href = value.length > 0 ? route + '?search=' + encodeURIComponent(value) : route
    });
  }


}(jQuery));
