/**
 * HeroSlider: with slick carousel library in mode modules
 * http://kenwheeler.github.io/slick/
 */
(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = ".HeroSlider";
  var TOLLENSSLIDER_SEL = ".HeroSlider--Tollens";

  /**
   * Init
   */
  function init() {
    var container = $(CONTAINER_SEL);
    if (container.length === 0) {
      return;
    }
    var tollensslider = $(TOLLENSSLIDER_SEL);
    if(tollensslider.length === 0){
      container.slick({
        dots: true,
        arrows: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000
      });
    } else {
      container.slick({
        dots: true,
        arrows: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000
      });
    }
    
  }
})(jQuery);
