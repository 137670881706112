Zolpan.onlinepayment = Zolpan.onlinepayment || {};

Zolpan.onlinepayment = (function($, ns){

  ns.init = function () {

    $.validator.addMethod( "simplifiedAlphanumeric", function( value, element ) {
      return this.optional( element ) || /^[A-Za-z0-9]+$/i.test( value );
    }, "Letters, numbers only please" );

    validateOnlinePaymentForm();

    // Disallow paste
    $('input[name="online_payment_form[email][second]"]').on('paste', function (event) {
      event.preventDefault();
    });

  };

  /**
   * Validate the online payment form
   */
  function validateOnlinePaymentForm() {
    $('form[name="online_payment_form"]').validate({
      errorClass: "Form-error",
      rules: {
        "online_payment_form[email][second]": {
          "equalTo": "input[name=\"online_payment_form[email][first]\"]"
        },
        "online_payment_form[client_account_number]":{
          "rangelength": [6, 8]
        },
        "online_payment_form[society_siren]": {
          "simplifiedAlphanumeric": "input[name=\"online_payment_form[society_siren]\"]"
        }
      },
      messages: {
        "online_payment_form[email][second]": "Les adresses mail doivent correspondre.",
        "online_payment_form[amount]": "Le montant doit être supérieur à 0.",
        "online_payment_form[client_account_number]": "Le compte client doit contenir entre 6 et 8 caractères.",
        "online_payment_form[society_siren]" : "Le SIREN ne doit contenir que des lettres et des chiffres"
      },
      errorPlacement: function(error, element) {
        if (element.attr("name") == "online_payment_form[termsofsales_flag]") {
          error.appendTo(".Card-cgu");
        } else {
          error.insertAfter(element);
        }
      }
    });
  }

  return ns;
})(jQuery, {});
