/**
 * Iframe external form Tollens
 */
 (function ($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var IFRAME_SEL = ".externalform-iframe iframe";

  /**
   * Init
   */
  function init() {
    var frame = $(IFRAME_SEL);
    if (frame.length === 0) {
      return;
    }
    //Staring FN once the iframe is loaded
    $('.externalform-iframe iframe').on("load", startIframeResize());
  }

  //Doc at https://github.com/davidjbradshaw/iframe-resizer and http://davidjbradshaw.github.io/iframe-resizer/
  function startIframeResize(){
    $('.externalform-iframe iframe').iFrameResize({
      log                     : false, // Enable console logging
      enablePublicMethods     : true,
      minHeight               : 20,
    });
  }
})(jQuery);