Zolpan.tracking = (function (ns, $) {

  // Initialization
  ns.init = function() {
    ns.initArticlePickerItemListener();
    ns.initArticlePickerProductSelectListener();
    ns.initArticlePickerTesterButtonListener();
    ns.initColorItemButtonListener();
    ns.initColorItemDetailsListener();
    ns.initColorSearchFilters();
    ns.initFavoriteColorListener();
    ns.initGdsListener();
    // ns.initGetUserId();
    ns.initHeaderSearchListener();
    ns.initHeroSliderContentListener();
    ns.initOrderTester();
    ns.initProductDetailButtonListener();
    ns.initProductListener();
    ns.initSharebarLinkListener();
    ns.initShareListener();
    ns.initStoreListener();
    ns.initStoreListItemListener();
    ns.initStoreLocatorDropdownListener();
    ns.initStoreLocatorListener();
    ns.initVideoListener();
  };

  ns.initArticlePickerItemListener = function() {
    $(document).on("click", ".ArticlePicker-Item",   function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "Produit",
        'eventAction': "Conditionnement",
        'eventLabel':  $(this).find("ArticlePicker-Item label").text(),
        'category':  $(document).find(".Product-titleLink").text()
      };

      dataLayer.push(data);
    })
  }

  ns.initArticlePickerProductSelectListener = function() {
    $(document).on("change", ".ArticlePicker-ProductSelect", function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "Produit",
        'eventAction': "Reference",
        'eventLabel':  $(this).find(":selected").text()
      };

      dataLayer.push(data);
    })
  }

  ns.initArticlePickerTesterButtonListener = function() {
    $(document).on("click", ".ArticlePicker-TesterButton",   function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "Produit",
        'eventAction': "Commande testeur",
        'eventLabel':  window.location.pathname
      };

      dataLayer.push(data);
    })
  }

  ns.initColorItemButtonListener = function() {
    $(document).on("click", ".ColorItem",   function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "Conseil",
        'eventAction': "Couleur",
        'eventLabel': $(this).find(".ColorItem-references").text()
      };

      dataLayer.push(data);
    })
  }

  ns.initColorItemDetailsListener = function() {
    $(document).on("click", ".ColorItem-details",   function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "Conseil",
        'eventAction': "Commande testeur",
        'eventLabel': $(this).find(".ColorItem-references").text()
      };

      dataLayer.push(data);
    })
  }

  ns.initColorSearchFilters = function(){
    $(document).on("click", ".Facets-item--checkbox", function (event) {
      var filterArray = [];

      // gets all the checked filers, we add them to an array
      if(document.querySelectorAll('.Facets-item--checked')){
        filterArray = Array.from(document.querySelectorAll('.Facets-item--checked'));
      };

      // if clicked filter is NOT checked, we add it to the array too
      if(!$(this).hasClass("Facets-item--checked")){
        filterArray.push($(this));
      };

      var filterObjectsArray = [];

      // if we have filters
      if(filterArray.length){

        // we create a filterObject to push to filterObjectsArray 
        filterArray.forEach(function(filter){
          var filterObject = {};
          filterObject['filter_id'] = $(filter).attr('data-facet');
          filterObject['filter_value'] = $(filter).text().trim();
          filterObjectsArray.push(filterObject);
        })
      };

      // we use the filterObjectsArray in the filters
      var data = {
        event: 'use_filters',
        filters_type: 'color',
        filters: filterObjectsArray
      };

      dataLayer.push(data);
    })
  };
  
  ns.initFavoriteColorListener = function() {
    $(document).on("click", ".bloc-add-favorite-color", function() {
      if(!$(this).closest(".ColorItem-link").hasClass("ColorItem-link--disabled")) {
        var data = {
          'event': 'eventGA',
          'eventCategory': 'Wishlist',
          'eventAction': 'Ajout wishlist',
          'eventLabel': $(this).closest(".ColorItem-tools").find(".ColorItem-ref").text(),
          'category': $(this).closest(".ColorItem-tools").find(".ColorItem-collection").text(),
        }
      }

      dataLayer.push(data);
    })
  }

  ns.initGdsListener = function() {
    $(document).on("click", ".js-gds .FormCheckBox-item label",   function () {

      var data = {
        'event': 'eventGA',
        'eventCategory': 'Guide des solutions',
        'eventAction': $(this).parent().find("input").attr("id").split("-")[1],
        'eventLabel': $(this).text().trim()
      }

      dataLayer.push(data);
    })
  }
  // moved inside src/Zolpan/SiteBundle/Resources/views/themes/tollens/pagelayout.html.twig to execute it before Didomi.
  // ns.initGetUserId = function(){
  //   var data = {
  //     'userId': Zolpan.vars.userID,
  //   }

  //   dataLayer.push(data);
  // };

  ns.initHeaderSearchListener = function() {
    var searchForm = $(".Header-search form");

    searchForm.on('submit', function(e){
      var data = {
        'event': "eventGA",
        'eventCategory': "Moteur",
        'eventAction': "Recherche",
        'eventLabel':  $(this).find("#searchformmain").val()
      };

      dataLayer.push(data);
    })
  }
  
  ns.initHeroSliderContentListener = function() {
    $(document).on("click", ".HeroSlider-content", function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "HP",
        'eventAction': "Carousel",
        'eventLabel':  $(this).find(".HeroSlider-title").text()
      };

      dataLayer.push(data);
    })
  }

  ns.initOrderTester = function(){
    $(document).on("click", ".ColorItem-shop .Button", function (event) {
      event.preventDefault();
      var data = {
        'event': 'productClick',
        'item_list_name': 'Nuancier',
        'item_list_id': 'nuancier',
        'color_reference': $(this).closest('.ColorItem-details').find('.ColorItem-references').text().trim().split(' ')[0],
        'color_brand': $(this).closest('.ColorItem-details').find('.ColorItem-brand').text().trim(),
        'ecommerce': {
          'click': {
            'products': [{
              'name': 'Testeur Cromology 100ml',
              'id': 'CR_TESTEUR',
            }]
          }
        }
      };

      dataLayer.push(data);

      if($(this).attr("target") === "_blank"){
        window.open($(this).attr("href"), '_blank').focus();
      } else {
        window.location = $(this).attr("href");
      }
    });
  };

  
  ns.initProductDetailButtonListener = function() {
    $(document).on("click", ".ProductDetail-Button",   function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "Produit",
        'eventAction': "Fiche technique",
        'eventLabel': $(document).find(".Product-titleLink").text()
      };

      dataLayer.push(data);
    })
  }
  
  ns.initProductListener = function() {
    $(document).on("click", ".Product",   function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "Produit",
        'eventAction': "Clic",
        'eventLabel': $(this).find(".Product-titleLink").text().replace(/(\r\n|\n|\r)/gm, "").trim(),
      };

      dataLayer.push(data);
    })
  }

  ns.initSharebarLinkListener = function() {
    $(document).on("click", ".ShareBar-item",   function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "Réseaux Sociaux",
        'eventAction': $(this).attr("aria-label"),
        'eventLabel':  window.location.pathname
      };

      dataLayer.push(data);
    })
  }
  
  ns.initShareListener = function() {
    $(document).on("click", ".Sharebar-item a", function () {
      var data = {
        'event': 'eventGA',
        'eventCategory': 'Réseaux Sociaux',
        'eventAction': 'Partage ' + $(this).attr('data-name'),
        'eventLabel': window.location.pathname
      }

      dataLayer.push(data);
    })
  }
  
  ns.initStoreListener = function() {
    $(document).on("click", ".js-store-tooltip-link", function() {
      var data = {
        'event': 'eventGA',
        'eventCategory': 'Magasin',
        'eventAction': 'Clic',
        'eventLabel': $(this).closest(".Map-tooltipContent").find(".Map-tooltipTitle").text()
      }

      dataLayer.push(data);
    })

    $(document).on("click", ".StoreList-item", function() {
      var data = {
        'event': 'eventGA',
        'eventCategory': 'Magasin',
        'eventAction': 'Clic',
        'eventLabel': $(this).attr('data-title-tracking')
      }

      dataLayer.push(data);
    })
  }
  
  ns.initStoreListItemListener = function() {
    $(document).on("click", ".Map-canvas [role=button]", function () {

      var data = { 
        'event': "eventGA", 
        'eventCategory': "Magasin", 
        'eventAction': "Clic", 
        'eventLabel': $(this).attr("title") 
      }

      dataLayer.push(data);
    })
  }
  
  ns.initStoreLocatorDropdownListener = function() {
    $(document).on("click", ".CromologyStoreLocator-SearchDropdown li",   function () {
      var data = {
        'event': "eventGA",
        'eventCategory': "Magasin",
        'eventAction': "Choix",
        'eventLabel': $(this).text
      };

      dataLayer.push(data);
    })
  }
  
  ns.initStoreLocatorListener = function() {
    $(document).on("click", ".CromologyStoreLocator-OutlinedButtonIcon",   function () {

      var data = { 
        'event': "eventGA", 
        'eventCategory': "Magasin", 
        'eventAction': "Géolocalisation"
      }

      dataLayer.push(data);
    })
  }

  ns.initVideoListener = function() {
    $(".Video-wrapper iframe").ready(function() {
      window.addEventListener('blur', function(e){
        if(document.activeElement == document.querySelector('.Video-wrapper iframe')) {
          var data = {
            'event': 'eventGA',
            'eventCategory': 'Réseaux Sociaux',
            'eventAction': 'Lecture video',
            'eventLabel': $(document.activeElement).closest(".Video").find(".Video-wrapper").attr("data-video")
          }

          dataLayer.push(data);
        }
      });
    })
  }

  $(document).ready(function () {
    ns.init();
  });

  return ns;
})({}, jQuery);
Zolpan.tracking = Zolpan.tracking || {};
