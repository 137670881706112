var Zolpan = Zolpan || {};

Zolpan.flashbag = function ($) {
    var $homeFlashBagContainer = $('#homeFlashBagContainer');
    var index = 0;
    var iconsvg_path = '/assets/themes/cromology/build/icons.svg';

    function init() {
        $.get(
            Routing.generate('zolpan.ajax.flashbag')
        ).done(function (flashbags) {
            for (var type in flashbags) {
                for (var message in flashbags[type]) {
                    showMessage(type, flashbags[type][message]);
                }
            }
        });

        Translator.locale = $('html').attr('lang');
    }

    function showMessage(type, message) {

        Translator.locale = $('html').attr('lang');

        index++;

        var html =
            "<div class='FlashBag FlashBag--" + type + "' id='flashbag" + index + "'>" +
            Translator.trans(message) +
            "<button class='FlashBag-close' data-toggle aria-controls='flashbag" + index + "'>" +
            "<svg class='icon icon--close' aria-hidden>" +
            "<use xlink:href='" + iconsvg_path + "#icon-close'></use>" +
            "</svg>" +
            "</button>" +
            "</div>";

        $homeFlashBagContainer.append(html);
    }

    $(window).ready(init);

    return {
        showSuccessMessage: function (message) {
            showMessage('success', message)
        },
        showErrorMessage: function (message) {
            showMessage('error', message)
        }
    };

}(jQuery);
