Zolpan.calculator = Zolpan.wallpaperCalculator || {};

Zolpan.form.wallpaperCalculator = (function ($) {

    var vanillaModal = new VanillaModal.default();

    var $calculateSubmitButton = $("button[name='calculateSubmit']");

    var wallpaperProjectNameModalId = '#WallpaperProjectNameModal';
    var $wallpaperFormContainer = $("#WallpaperFormContainer");
    var $wallpaperCalculatorForm = $("#FormWallpaperCalculator");
    var $wallpaperProjectNameForm = $("#WallpaperProjectNameForm");
    var $wallpaperSurfaceResult = $("#WallSurfaceResult");

    function init() {
        changeCommaByPoint();
        calculateWallpaperSurface();
        validateWallpaperProjectForm();
        listenWallpaperProjectFormSubmission();
    }

    function changeCommaByPoint() {
        $('#FormWallpaperCalculator input').on('keyup', function () {
            var $self = $(this);
            if ($self.val().includes(',')) {
                $self.val($self.val().replace(',', '.'));
            }
        });
    }

    function calculateWallpaperSurface() {
        var wallLengthSelector = "input[id$='wallLength']";
        var wallHeightSelector = "input[id$='wallHeight']";

        var $wallLengthInput = $wallpaperCalculatorForm.find(wallLengthSelector);
        var $wallHeightInput = $wallpaperCalculatorForm.find(wallHeightSelector);

        if ($wallLengthInput.length > 0 && $wallHeightInput.length > 0) {
            renderSurface($wallLengthInput, $wallHeightInput);
            $(wallLengthSelector + "," + wallHeightSelector).on('keyup', function () {
                renderSurface($wallLengthInput, $wallHeightInput);
            });
        }

        function renderSurface($wallLengthInput, $wallHeightInput) {

            var wallLengthValue = parseFloat($wallLengthInput.val().replace(',', '.')).toFixed(2);
            var wallHeightValue = parseFloat($wallHeightInput.val().replace(',', '.')).toFixed(2);

            if ($.isNumeric(wallLengthValue) && $.isNumeric(wallHeightValue)) {
                var result = wallLengthValue * wallHeightValue;
                if ($.isNumeric(result)) {
                    result = result.toFixed(2);
                }
                $wallpaperSurfaceResult.html(result);
            }
        }
    }

    function validateWallpaperProjectForm() {
        $wallpaperCalculatorForm.validate({
            errorClass: "FormItem-info FormItem-info--error",
            errorPlacement: function (error, element) {
                error.insertAfter(element);
                element.closest('.FormItem').addClass('FormItem--error');
            },
            unhighlight: function (element) {
                $(element.closest('.FormItem')).removeClass('FormItem--error');
            }
        });
    }

    function listenWallpaperProjectFormSubmission() {
        $wallpaperCalculatorForm.find("button[name='saveSubmit']").on('click', function () {
            var openModal = vanillaModal.open(wallpaperProjectNameModalId);

            $.when(openModal).done(function () {

                $wallpaperProjectNameForm.on('submit', function (event) {
                    event.preventDefault();

                    // add a new value (projectName) without changing request Array for PHP handling
                    var data = $wallpaperCalculatorForm.serializeArray();
                    data.push({name: 'projectName', value: $(this).find('#WallpaperProjectName').val()});

                    $.ajax({
                        url: Routing.generate('zolpan.form.wallpaper_project.submit'),
                        data: data,
                        type: 'POST',
                        statusCode: {
                            201: function (url) {
                                window.location.href = url
                            },
                            500: function (response) {
                                vanillaModal.close(wallpaperProjectNameModalId);
                                Zolpan.flashbag.showErrorMessage(response.responseText);
                                $calculateSubmitButton.prop('disabled', true);
                            },
                            400: function (response) {
                                vanillaModal.close(wallpaperProjectNameModalId);
                                $wallpaperCalculatorForm.remove();
                                $wallpaperFormContainer.html(response.responseText);
                            },
                            403: function (response) {
                                vanillaModal.close(wallpaperProjectNameModalId);
                                Zolpan.flashbag.showErrorMessage(response.responseText);
                                $calculateSubmitButton.prop('disabled', true);
                            }
                        }
                    });

                });
            });
        });
    }

    $(document).ready(init);

})
(jQuery);
