var Zolpan = Zolpan || {};

Zolpan['events'] = Zolpan.events || {
    vars:
        jQuery.ajax({url: Routing.generate('cromology_front_vars')}).done(function (datas) {
            jQuery.extend(Zolpan.vars, datas);
        }),
};

Zolpan.storedetail = function($) {

    if($('#ColorChart-container') != 'undefined') {
        $(document).ready(function(e) {
            $('.Loader').hide();
            $('#ColorChart-container').show();
        })
    }

}(jQuery);
