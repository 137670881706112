;
/**
 *  Smooth anchor scrolling
 */
(function smoothScrolling ($) {

  $(window).on("load",init);

  var WIDGET_SEL = 'a[href*="#"]:not([data-modal-open])';
  var HEADER_SEL = '.Header';

  function init() {

    var headerHeight = $(HEADER_SEL).outerHeight();

    $("body").on("click", WIDGET_SEL, function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        if( !target.length && this.hash.length > 1) {
          target = $('[name=' + this.hash.slice(1) + ']');
        }
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top - headerHeight
          }, 1000);
          return false;
        }
      }
    });

  }


}(jQuery));
