var Cromology = Cromology || {};
Cromology.catalog = (function ($) {

    if($('.js-catalog').length > 0) {

        var catalogId = $('.js-catalog').attr('data-catalog');
        var paginationSel = $('.CatalogPaginationSelect');
        var paginationPrev = $('.CatalogPaginationPrevious');
        var paginationNext = $('.CatalogPaginationNext');
        var paginationNextPrev = $('.CatalogPaginationArrow');

        paginationSel.on('change', function() {
            var pageIndex = $(this).children("option:selected").attr("data-index");
            var offset = (pageIndex - 1) * 24;

            var currentUrl = removeQueryStringParameter(window.location.href, 'p');
            currentUrl = updateQueryStringParameter(currentUrl, 'p', pageIndex);

            window.history.pushState("Catalogue Produits", "Catalogue Produits", currentUrl);

            var searchUrl = Routing.generate('ajax_catalog_search');
            searchUrl = updateQueryStringParameter(searchUrl, 'catalog', catalogId);

            $.each(getAllUrlParams(), function(index, value) {
                if(Array.isArray(value) && value != "") {
                    searchUrl = updateQueryStringParameter(searchUrl, value[0], value[1]);
                }
            });
            updateFacetsProducts(searchUrl, offset);
        });

        paginationNextPrev.on('click', function(e) {
            e.preventDefault();

            var pageIndex = Number($(this).attr("data-index"));
            var offset = (pageIndex - 1) * 24;

            paginationSel.val(pageIndex);
            paginationPrev.attr("data-index", pageIndex - 1);
            paginationNext.attr("data-index", pageIndex + 1);

            var currentUrl = removeQueryStringParameter(window.location.href, 'p');
            currentUrl = updateQueryStringParameter(currentUrl, 'p', pageIndex);

            window.history.pushState("Catalogue Produits", "Catalogue Produits", currentUrl);

            var searchUrl = Routing.generate('ajax_catalog_search');
            searchUrl = updateQueryStringParameter(searchUrl, 'catalog', catalogId);

            $.each(getAllUrlParams(), function(index, value) {
                if(Array.isArray(value) && value != "") {
                    searchUrl = updateQueryStringParameter(searchUrl, value[0], value[1]);
                }
            });

            updateFacetsProducts(searchUrl, offset);
        });

        // ON CLICK UNSELECTED FACETS
        $(document).on('click', '.Facets-item.Facets-item--checkbox', function(e) {
            if($(this).attr("data-redirect") === undefined) {
                e.preventDefault();
                var key = $(this).attr("data-facet").split("=")[0];
                var value = $(this).attr("data-facet").split("=")[1];

                //var currentUrl = new URL(window.location.href);
                var currentUrl = window.location.href;

                var searchUrl = Routing.generate('ajax_catalog_search');
                //var searchUrl = window.location.href;

                // Move checkbox to selected area
                var clickedLi = $(this).closest('li');
                var selectedLi = clickedLi.clone();
                selectedLi.find('a').removeClass('Facets-item--checkbox').addClass('Facets-item--selected').attr("aria-label", "supprimer le filtre " + $(this).text());

                clickedLi.hide();
                clickedLi.addClass("js-facet-list-disabled");

                // If the facet group has no facets under, hide it
                /*if(clickedLi.closest('Facets-group').find("li:not('.js-facet-list-disabled')").length == 0) {
                 clickedLi.closest(".Facets-group").hide();
                 }*/

                // Append the cloned LI to active facets container and show it
                $('.js-facets-active-list').append(selectedLi);
                $('.js-facets-active-list').closest('.Facets-group').removeClass('displayNone');
                $('.js-facets-active-list').closest('.Facets-group').removeClass('hidden');
                $('.js-facets-active-list').closest('.Facets-group').show();
                /* -------------------------------------------------------------------------- */

                // Add catalog Id in URL
                searchUrl = updateQueryStringParameter(searchUrl, 'catalog', catalogId);
                //currentUrl = updateQueryStringParameter(currentUrl, 'catalog', catalogId);

                // Add facets parameters in URL
                searchUrl = updateQueryStringParameter(searchUrl, key, value);
                currentUrl = updateQueryStringParameter(currentUrl, key, value);

                // Remove offset parameters
                currentUrl = removeQueryStringParameter(currentUrl, "offset");
                currentUrl = removeQueryStringParameter(currentUrl, "p");

                // Update browser url
                window.history.pushState("Catalogue Produits", "Catalogue Produits", currentUrl);

                // Add all previous parameters in the searchUrl
                $.each(getAllUrlParams(), function (index, value) {
                    if (Array.isArray(value) && value != "") {
                        searchUrl = updateQueryStringParameter(searchUrl, value[0], value[1]);
                        currentUrl = updateQueryStringParameter(currentUrl, value[0], value[1]);
                    }
                });

                updateFacetsProducts(searchUrl);
            }
        });

        // CLICK ON SELECTED ITEM
        $(document).on('click', '.Facets-item.Facets-item--selected', function(e) {
            e.preventDefault();

            if($(this).attr("data-redirect") === undefined) {
                var key = $(this).attr("data-facet").split("=")[0];
                var value = $(this).attr("data-facet").split("=")[1];
                var currentUrl = window.location.href;
                var searchUrl = Routing.generate('ajax_catalog_search');

                // Remove the clicked facet from the url
                currentUrl = updateQueryStringParameter(currentUrl, key, value, true);

                // Remove offset parameters
                currentUrl = removeQueryStringParameter(currentUrl, "offset");
                currentUrl = removeQueryStringParameter(currentUrl, "p");

                // Update the browser url
                window.history.pushState("Catalogue Produits", "Catalogue Produits", currentUrl);

                // Buid the search url with all remaining url parameters
                $.each(getAllUrlParams(), function (index, value) {
                    if (Array.isArray(value) && value != "") {
                        searchUrl = updateQueryStringParameter(searchUrl, value[0], value[1]);
                        currentUrl = updateQueryStringParameter(currentUrl, value[0], value[1]);
                    }
                });

                searchUrl = updateQueryStringParameter(searchUrl, 'catalog', catalogId);

                //Remove the clicked facet (not seleted anymore)
                $(e.target).closest('li').remove();

                // Move checkbox to selected area
                if ($('.js-facets-active-list li').length == 0) {
                    $('.js-facets-active-list').closest('.Facets-group').addClass('displayNone');
                }

                updateFacetsProducts(searchUrl);
            } else {
                var currentUrl = window.location.href;

                var str = currentUrl.substr(currentUrl.lastIndexOf('/'));
                str = escapeRegExp(str);
                str = str + '$';
                window.location.replace(currentUrl.replace( new RegExp(str), '' ));
            }
        });

        // ON CLICK BUTTON CLEAR ALL FILTERS
        $(document).on('click', '.js-clear-filters-btn', function(e) {
            e.preventDefault();

            var redirect = false;
            $.each($('.js-facets-active-list li a'), function(index, value) {
                if($(value).attr("data-redirect") !== undefined) {
                    redirect = true;
                }
            });
            if(!redirect) {
                // Remove all selected facet and hide the selected facet container
                $('.js-facets-active-list').closest('.Facets-group').addClass('displayNone');
                $('.js-facets-active-list li').remove();

                var searchUrl = Routing.generate('ajax_catalog_search');
                var catalogId = $('.js-catalog').attr('data-catalog');

                // Get the search url only based on the catalogId
                searchUrl = updateQueryStringParameter(searchUrl, 'catalog', catalogId);
                updateFacetsProducts(searchUrl);

                // Update browser url
                window.history.pushState("Catalogue Produits", "Catalogue Produits", window.location.href.split('?')[0]);
            } else {
                var currentUrl = window.location.href;

                var str = currentUrl.substr(currentUrl.lastIndexOf('/'));
                str = escapeRegExp(str);
                str = str + '$';
                window.location.replace(currentUrl.replace( new RegExp(str), '' ));
            }
        });

        // ON DOCUMENT LOAD -> UUPDATE Facets/Products following the url parameters
        $(document).ready(function () {
            // Remove offset parameters
            var currentUrl = window.location.href;
            currentUrl = removeQueryStringParameter(currentUrl, "offset");
            window.history.pushState("Catalogue Produits", "Catalogue Produits", currentUrl);

            // Lopp on all url parameters
            $.each(getAllUrlParams(), function(index, value) {
                if(Array.isArray(value) && value != "") {
                    $.each(value[1].split(","), function(i, v) {
                        // Get the corresponding facet <li>
                        var facetLi = $(".Facets-item--checkbox[data-facet='"+value[0]+'='+v+"']").closest('li');

                        var selectedLi = facetLi.clone();

                        // The cloned facet <li> will be append to the selected facet container
                        selectedLi.find('a').removeClass('Facets-item--checkbox').addClass('Facets-item--selected').attr("aria-label", "supprimer le filtre " + v);

                        // Hide the facet from the facet list
                        facetLi.hide();
                        facetLi.addClass("js-facet-list-disabled");

                        // Append the cloned facet <li>
                        $('.js-facets-active-list').append(selectedLi);
                        $('.js-facets-active-list').closest('.Facets-group').removeClass('displayNone');
                        $('.js-facets-active-list').closest('.Facets-group').removeClass('hidden');
                    });
                }
            });

            // Loop on all facet groups to check if there is facet on it, hide it if it's the case
            $.each($(".Facets .Facets-group"), function(index, value) {
                if($(value).find("li:not('.js-facet-list-disabled')").length == 0) {
                    $(value).hide();
                } else {
                    $(value).show();
                    $(value).removeClass('hidden');
                }
            });

            var numProductsFound = parseInt($('.js-product-numfound').text());
            if(numProductsFound <= 24) {
                $('.js-load-more-products').closest('p').hide();
            }
        });

        // BUTTON LOAD MORE PRODUCTS
        $(document).on('click', '.js-load-more-products', function(e) {
            e.preventDefault();

            var searchUrl = Routing.generate('ajax_catalog_search');
            var catalogId = $('.js-catalog').attr('data-catalog');

            $.each(getAllUrlParams(), function(index, value) {
                if(Array.isArray(value) && value != "") {
                    searchUrl = updateQueryStringParameter(searchUrl, value[0], value[1]);
                }
            });

            // Get the search url only based on the catalogId
            searchUrl = updateQueryStringParameter(searchUrl, 'catalog', catalogId);

            var url = new URL(window.location.href);
            var offset = url.searchParams.get("offset");

            if(offset == null) {
                offset = 24;
            } else {
                offset = parseInt(offset) + 24;
            }

            var currentUrl = updateQueryStringParameter(window.location.href, 'offset', offset);
            window.history.pushState("Catalogue Produits", "Catalogue Produits", currentUrl);

            updateFacetsProducts(searchUrl, offset, false);

        });
    }

    function escapeRegExp(text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    function updateQueryStringParameter(uri, key, value, remove) {
        var newUrl;
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";

        // RegExp to get current value of the given parameter
        var matches = uri.match(new RegExp(key + "=([a-z0-9,-_]*)?(&|$)", "i"));
        if(matches != null && matches[1] != null) {
            var previousValue = matches[1];

            if(remove != undefined && remove == true) {
                var newValue = previousValue.replace(new RegExp("[,]?" + value), "");
                newValue = newValue.replace(new RegExp("^,"), "");

                if (newValue == "") {
                    if(value.indexOf("?")) {
                        newUrl =  uri.replace(re, '?');
                    } else {
                        newUrl = uri.replace(re, '');
                    }

                } else {
                    newUrl = uri.replace(re, '$1' + key + "=" + newValue + '$2');
                }
            }

            if(newUrl != undefined) {
                newUrl = newUrl.replace(new RegExp("\\?$"), "");
                return newUrl;
            }
        }

        if (uri.match(re)) {
            if(previousValue != undefined && previousValue != value && key != "offset") {
                newUrl = uri.replace(re, '$1' + key + "=" + value + ',' + previousValue + '$2');
            } else {
                newUrl = uri.replace(re, '$1' + key + "=" + value + '$2');
            }
        }
        else {
            newUrl = uri + separator + key + "=" + value;
        }

        newUrl = newUrl.replace(new RegExp("\\?$"), "");
        newUrl = orderUrlParams(newUrl);

        return newUrl;
    }

    function orderUrlParams(url) {
        var params = {};
        var urlPart = url.split('?')[0];
        var paramsPart = url.split('?')[1];

        var sortedUrl = urlPart;

        var paramsStr = paramsPart.split('&');

        for (var i = paramsStr.length - 1; i >= 0; i--) {
            params[paramsStr[i].split('=')[0]] = paramsStr[i].split('=')[1];
        };

        var sortedParams = Object.keys(params).sort();

        for(var i = 0; i < sortedParams.length; i++) {
            if(i == 0)
                sortedUrl += "?";

            sortedUrl += sortedParams[i] + "=" + params[sortedParams[i]];

            if(i < sortedParams.length - 1)
                sortedUrl += "&";

        }

        return sortedUrl;
    }

    function removeQueryStringParameter(uri, key) {
        var newUrl;
        var re = new RegExp("([&]?)" + key + "=.*?(&|$)", "i");
        if(uri.match(re)) {
            newUrl = uri.replace(re, '');
            newUrl = newUrl.replace(new RegExp("\\?$"), "");
            return newUrl;
        }
        uri = uri.replace(new RegExp("\\?$"), "");
        return uri;
    }

    function getAllUrlParams() {
        var keyPairs = [],
            params = window.location.search.substring(1).split('&');
        for (var i = params.length - 1; i >= 0; i--) {
            keyPairs.push(params[i].split('='));
        };
        return keyPairs;
    }

    function updateFacetsProducts(searchUrl, offset, clearProducts) {
        if(offset == undefined || offset == null) {
            searchUrl = updateQueryStringParameter(searchUrl, 'offset', 0);
        } else {
            searchUrl = updateQueryStringParameter(searchUrl, 'offset', offset);
        }
        /*
         This function will call the given back-end url.
         Return a JSON Object with facets, products and numFound.
         */
        $.ajax({
            url: searchUrl,
            type: "GET",
            success: function (data) {
                // UPDATE FACETS LIST //

                // Loop through all facet in the JSON Object.
                // facetIndex = id of the facet, example 'categories##categories'
                // facetValue = array of the facetKey(exemple 'Camions##camions') => number of results in the facet
                $.each(data.facets, function(facetIndex, facetValue) {
                    // Get the facet list (group of facet) corresponding to the facet ID
                    var facetList = $(".Facets-list[data-facet-list='"+facetIndex+"']");

                    // Loop through all facet on the list
                    $.each(facetList.find('li'), function(indexLi, valueLi) {
                        var facetLi = $(valueLi);
                        var facetLiData = facetLi.attr('data-facet');
                        var facetALink = facetLi.find('a').attr('data-facet');

                        // If facet still has results, update results number on it
                        if(facetValue.hasOwnProperty(facetLiData) && facetValue[facetLiData] > 0) {
                            if($(".Facets-item--selected[data-facet='"+facetALink+"']").length == 0) {
                                facetLi.show();
                                facetLi.removeClass("js-facet-list-disabled");
                            }
                            var numFound = facetValue[facetLiData];
                            facetLi.find('.Facets-number').text("("+numFound+")");
                            // If facet has no results, remove it
                        } else {
                            facetLi.hide();
                            facetLi.addClass("js-facet-list-disabled");
                        }
                    });

                    // Loop through all facets from the given facetList
                    // The goal is to create facet which are not in the document
                    // It can happen when the user comes with an url with parameters, the first loading of the DOM will only create facet which have products.
                    $.each(facetValue, function(facetName, facetCount) {
                        var facetValue = facetName.split("##")[1];
                        var facetLabel = facetName.split("##")[0];

                        if(facetList.find('li[data-facet=\"'+facetName.replace(/"/g, '')+'\"]').length == 0 && facetCount > 0) {
                        // if(facetList.find("a[data-facet='"+facetIndex.split("##")[0]+"="+facetValue+"']").length == 0 && facetCount > 0) {
                            var newFacetHTML = "<li data-facet='" + facetName.replace(/"/g, '') + "'><a class='Facets-item Facets-item--checkbox' href='#' data-facet='"+facetIndex.split('##')[0]+"="+facetValue+"'>"+facetLabel+"<span class='Facets-number'> ("+facetCount+")</span></a></li>";
                            facetList.append(newFacetHTML);
                        }
                    });

                    // Check if the facet list has facets on it, hide or show it depending on the result
                    if(facetList.find("li:not('.js-facet-list-disabled')").length == 0) {
                        facetList.closest(".Facets-group").hide();
                    } else {
                        facetList.closest(".Facets-group").show();
                    }
                });

                // UPDATE PRODUCTS LIST
                var productList = $('.js-product-list');

                if(clearProducts == undefined || clearProducts == null || clearProducts == true) {
                    productList.find('article').remove();
                }

                $.each(data.products, function(index, productHtml) {
                    productList.append(productHtml);
                });

                // UPDATE PRODUCT NUMFOUND
                $('.js-product-numfound').text(data.numFound);

                // UPDATE LOAD MORE BUTTON
                var url = new URL(window.location.href);
                var offset = url.searchParams.get("offset");
                var page = parseInt(url.searchParams.get("p"));
                var pagesNumber = Math.ceil(data.numFound / 24);

                if (paginationSel.children('option').length !== pagesNumber) {
                    paginationSel.empty();
                    for (var i = 1; i <= pagesNumber; i++) {
                        var optionLabel = i + ' sur ' + pagesNumber;

                        var newOption = $('<option>', {
                            value: i,
                            text: optionLabel,
                            'data-index': i,
                            'aria-label': optionLabel
                        });

                        if (i === 1) {
                            newOption.attr('selected', 'selected');
                        }

                        paginationSel.append(newOption);
                    }
                }

                if(!page) {
                    page = 1;
                    offset = 0;
                } else {
                    offset = (page-1) * 24;
                }

                if (data.numFound > 24) {
                    paginationSel.val(page);
                    paginationSel.show();

                    var currentUrl = removeQueryStringParameter(window.location.href, 'p');
                    var prevUrl = updateQueryStringParameter(currentUrl, 'p', page - 1);
                    var nextUrl = updateQueryStringParameter(currentUrl, 'p', page + 1);

                    paginationPrev.attr("data-index", page - 1);
                    paginationPrev.attr("href", prevUrl);
                    if (offset >= 24) {
                        paginationPrev.show();
                    } else {
                        paginationPrev.hide();
                    }

                    paginationNext.attr("data-index", page + 1);
                    paginationNext.attr("href", nextUrl);
                    if (offset + 24 < data.numFound) {
                        paginationNext.show();
                    } else {
                        paginationNext.hide();
                    }

                } else {
                    paginationSel.hide();
                    paginationPrev.hide();
                    paginationNext.hide();
                }

                // Scroll to top only if the user didn't click on load more products button
                if(clearProducts == undefined || clearProducts == null || clearProducts == true) {
                    scrollTo($('body'));
                }
            }
        });
    }

    function scrollTo(elem) {
        $('html,body').animate({
            scrollTop: elem.offset().top - $('.Header').outerHeight()
        }, 1000);
    }

})(jQuery);