var Cromology = Cromology || {};
Cromology.faq = (function ($) {
    if($('.js-faq-count-page-view').length > 0) {
        var url = Routing.generate('cromology_add_page_view', {
            contentId: $('.js-faq-count-page-view').attr('data-contentId')
        });

        $.get(url);
    }

    // var activeThemesLocationId = [];
    var activeThemeLocationId = null;

    $(document).on('click', '.js-faq-theme-filter', function(e) {
        e.preventDefault();

        var themeLocationId = $(this).attr('data-themelocationid');

        if($(this).hasClass('Facets-item--checked')) {
            activeThemeLocationId = null;
            $(this).removeClass('Facets-item--checked');
            $(this).blur();
        } else {
            activeThemeLocationId = themeLocationId;
            $('.Facets-item--checked').removeClass('Facets-item--checked');
            $(this).addClass('Facets-item--checked');
        }

        if(activeThemeLocationId != null) {
            $(".js-faq-question-result").hide();

            $(".js-faq-question-result[data-themelocationid='" + activeThemeLocationId + "']").show();
        } else {
            $(".js-faq-question-result").show();
        }
    })

    this.highlightKeywords = function(keywords, title){
        var words = keywords.split(" ");
        var span = "<span class='FaqCromology-searchKeyword'></span>";

        $.each(words, function(key, value) {
            if(span.indexOf(value) == -1) {
                var re = new RegExp(value, "i");
                title = title.replace(re, "<span class='FaqCromology-searchKeyword'>$&</span>");
            }
        })

        return title;
    }

    return this;

})(jQuery);