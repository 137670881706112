/**
 * Load More button
 */
(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var BTN_SEL = "[data-loader]";
  var CONTAINER_SEL = "[data-loader-container]";

  /**
   * Init
   */
  function init() {
    var button = $(BTN_SEL);
    if (button.length === 0) {
      return;
    }

    if ($(CONTAINER_SEL) != 'undefined') {
      $(BTN_SEL).hide();
      $(CONTAINER_SEL).show();
    }

  }
})(jQuery);
