Zolpan.form = Zolpan.form || {};
Zolpan.form.registration = (function ($) {

  var $registrationFormContainer = $("#registrationFormContainer");
  var $profesionalRadioButton = $("#profesionalRadioButton");
  var $individualRadioButton = $("#individualRadioButton");

  var $profesionalForm = $('#FormProfesional');
  var $individualForm = $('#FormIndividual');

  var similarMailErrorMessage = "Les adresses mail doivent correspondre.";
  var similarPasswordErrorMessage = "Les mots de passe doivent correspondre.";

  function init() {
    initCustomValidationRules();
    listenRegistrationRadioButtons();
    forbidConfirmationEmailPaste();
    validateIndividualForm();
    validateProfesionalForm();
  }

  function listenRegistrationRadioButtons() {

    if ($individualRadioButton.attr('checked') === 'checked') {
        $individualForm.show();
        $profesionalForm.hide();
    } else if ($profesionalRadioButton.attr('checked') === 'checked') {
        $profesionalForm.show();
        $individualForm.hide();
    }

    $profesionalRadioButton.on('click', function () {
      $profesionalForm.show();
      $individualForm.hide();
    });

    $individualRadioButton.on('click', function () {
      $profesionalForm.hide();
      $individualForm.show();
    });
  }

  function forbidConfirmationEmailPaste() {
    $registrationFormContainer.on('paste', 'input[name$="form[email][second]"]', function (event) {
      event.preventDefault();
    });
  }

  function initCustomValidationRules() {

    jQuery.validator.addMethod("zolpan_password", function (value, element) {
        return this.optional(element) || /^(\w|[!_@#$%-]){6,20}$/.test(value);
      }, 'Votre mot de passe doit contenir entre 6 et 20 caractères (caractères spéciaux autorisés : "!", "_", "@", "#", "$", "%", "-")'
    );

    jQuery.validator.addMethod("zolpan_phone", function (value, element) {
      Translator.locale = $('html').attr('lang');

      if (document.querySelector("[data-form-robbialac]")) {
        return this.optional(element) || /^351-\d{9}$/.test(value);
      }
      return this.optional(element) || /^(\+(\d){2}|0)\d{9}$/.test(value);
    }, Translator.trans('contact.phone.format'))

  }

  function validateIndividualForm() {

    $individualForm.validate({
      errorClass: "FormItem-info FormItem-info--error",
      rules: {
        "individual[phone]": {
          'zolpan_phone': true
        },
        "individual[password][second]": {
          "equalTo": "input[name=\"individual[password][first]\"]"
        },
        "individual[password][first]": {
          'zolpan_password': true
        },
        "individual[email][second]": {
          "equalTo": "input[name=\"individual[email][first]\"]"
        }
      },
      messages: {
        "individual[email][second]": similarMailErrorMessage,
        "individual[password][second]": similarPasswordErrorMessage
      },
      errorPlacement: function (error, element) {
        if (element.attr("name") === "individual[civility]") {
          error.appendTo($(element).closest('fieldset'));
        } else {
          error.insertAfter(element);
        }
        element.closest('.FormItem').addClass('FormItem--error');
      },
      unhighlight: function (element) {
        $(element.closest('.FormItem')).removeClass('FormItem--error');
      }
    });
  }

  function validateProfesionalForm() {
    $profesionalForm.validate({
      errorClass: "FormItem-info FormItem-info--error",
      rules: {
        "profesional[phone]": {
          'zolpan_phone': true
        },
        "profesional[password][second]": {
          "equalTo": "input[name=\"profesional[password][first]\"]"
        },
        "profesional[password][first]": {
          'zolpan_password': true
        },
        "profesional[email][second]": {
          "equalTo": "input[name=\"profesional[email][first]\"]"
        }
      },
      messages: {
        "individual[email][second]": similarMailErrorMessage,
        "individual[password][second]": similarPasswordErrorMessage
      },
      errorPlacement: function (error, element) {
        if (element.attr("name") === "profesional[civility]") {
          error.appendTo($(element).closest('fieldset'));
        } else {
          error.insertAfter(element);
        }
        element.closest('.FormItem').addClass('FormItem--error');
      },
      unhighlight: function (element) {
        $(element.closest('.FormItem')).removeClass('FormItem--error');
      }
    });
  }

  $(document).ready(init);

})(jQuery);
