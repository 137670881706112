/**
 * Rubrics: with slick carousel library in mode modules
 * http://kenwheeler.github.io/slick/
 */
(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = ".Rubrics";

  /**
   * Init
   */
  function init() {
    var container = $(CONTAINER_SEL);
    if (container.length === 0) {
      return;
    }

    container.slick({
      slidesToShow: 3,
      arrows: false,
      slidesToScroll: 3
    });
  }
})(jQuery);
