var Zolpan = Zolpan || {};

Zolpan.simulator.api = {

    init: function () {
    },

    getUrl: function () {
        return $('#site_url').val();
    },

    createSimulation: function (dataJson) {

        if (typeof dataJson !== 'object') {
            dataJson = JSON.parse(dataJson);
        }

        var json = JSON.stringify(dataJson);
        console.log('create simulation data id = ' + dataJson.id);
        console.log('create simulation data name = ' + dataJson.name);
        console.log('create simulation data URL pic = ' + dataJson.imageURL);
        console.log('json stringify = ' + json);

        return ['POST', '/simulation/create', json];
    },

    updateSimulation: function (dataJson) {

        if (typeof dataJson !== 'object') {
            dataJson = JSON.parse(dataJson);
        }

        var json = JSON.stringify(dataJson);
        console.log('create simulation data id = ' + dataJson.id);
        console.log('create simulation data name = ' + dataJson.name);
        console.log('create simulation data URL pic = ' + dataJson.imageURL);
        console.log('json stringify = ' + json);

        return ['POST', '/simulation/update', json];
    },

    deleteSimulation: function (dataJson) {
        if (typeof dataJson !== 'object') {
            dataJson = JSON.parse(dataJson);
        }
        var json = JSON.stringify(dataJson);
        console.log('delete simulation data id = ' + dataJson.id);

        return ['POST', '/simulation/delete', json];
    },

    account: function () {
        return Cromology.simulator.api.getUrl();
    },

    ready: function () {
        var json = '';
        return ['GET', '/account/set', json];
    }
};