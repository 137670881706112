Zolpan.paintCalculator = Zolpan.paintCalculator || {};

Zolpan.form.paintCalculator = (function ($) {

    var vanillaModal = new VanillaModal.default();

    var $calculateSubmitButton = $("button[name='calculateSubmit']");

    var paintProjectNameModalId = '#PaintProjectNameModal';
    var $paintFormContainer = $("#PaintFormContainer");
    var $paintCalculatorForm = $("#FormPaintCalculator");
    var $paintProjectNameForm = $("#PaintProjectNameForm");

    function init() {
        calculatePaintSurfaces();
        validatePaintProjectForm();
        listenPaintProjectFormSubmission();
    }

    function calculatePaintSurfaces() {
        var $axeInputs = $paintCalculatorForm.find('input[data-mesure][data-axe]');

        $axeInputs.each(function () {
            renderSurface($(this));
        });

        $paintCalculatorForm.on('keyup', 'input[data-mesure][data-axe]', function () {
            renderSurface($(this));
            renderTotalSurface();
        });

        renderTotalSurface();

        function renderTotalSurface() {
            var $axesSupports = $paintCalculatorForm.find("span[data-support][data-surface]:not([data-mesure])");

            $axesSupports.each(function () {
                var sum = 0;
                $("span[data-support=" + $(this).data('support') + "][data-mesure][data-surface]").each(function () {
                    var value = parseFloat($(this).html());
                    if ($.isNumeric(value)) {
                        sum += parseFloat($(this).html());
                    }
                });
                if ($.isNumeric(sum)) {
                    sum = sum.toFixed(2);
                }
                $(this).html(sum);
            });
        }

        function renderSurface($axeInputs) {
            if($axeInputs.val().includes(',')) {
                $axeInputs.val($axeInputs.val().replace(',', '.'));
            }

            var axes = $paintCalculatorForm.find('input[data-support=' + $axeInputs.data('support') + '][data-mesure=' + $axeInputs.data('mesure') + '][data-axe]');
            var surfaceMesure = parseFloat($(axes[0]).val()) * parseFloat($(axes[1]).val());
            if ($.isNumeric(surfaceMesure)) {
                surfaceMesure = surfaceMesure.toFixed(2);
            }
            $paintCalculatorForm.find('span[data-support=' + $axeInputs.data('support') + '][data-mesure=' + $axeInputs.data('mesure') + '][data-surface]').html(surfaceMesure);
        }
    }

    function validatePaintProjectForm() {
        $paintCalculatorForm.validate({
            errorClass: "FormItem-info FormItem-info--error",
            errorPlacement: function (error, element) {
                if (element.attr("name") === "paint_project[roomObjectId]") {
                    error.appendTo($(element).closest('fieldset'));
                } else {
                    error.insertAfter(element);
                }

                element.closest('.FormItem').addClass('FormItem--error');
            },
            unhighlight: function (element) {
                $(element.closest('.FormItem')).removeClass('FormItem--error');
            }
        });
    }

    function listenPaintProjectFormSubmission() {

        $paintCalculatorForm.find("button[name='saveSubmit']").on('click', function () {
            var openModal = vanillaModal.open(paintProjectNameModalId);

            $.when(openModal).done(function () {
                $paintProjectNameForm.on('submit', function (event) {
                    event.preventDefault();

                    // add a new value (projectName) without changing request Array for PHP handling
                    var data = $paintCalculatorForm.serializeArray();
                    data.push({name: 'projectName', value: $(this).find('#PaintProjectName').val()});

                    $.ajax({
                        url: Routing.generate('zolpan.form.paint_project.submit'),
                        data: data,
                        type: 'POST',
                        statusCode: {
                            201: function (url) {
                                window.location.href = url
                            },
                            500: function (response) {
                                vanillaModal.close(paintProjectNameModalId);
                                Zolpan.flashbag.showErrorMessage(response.responseText);
                                $calculateSubmitButton.prop('disabled', true);
                            },
                            400: function (response) {
                                vanillaModal.close(paintProjectNameModalId);
                                $paintCalculatorForm.remove();
                                $paintFormContainer.html(response.responseText);
                            },
                            403: function (response) {
                                vanillaModal.close(paintProjectNameModalId);
                                Zolpan.flashbag.showErrorMessage(response.responseText);
                                $calculateSubmitButton.prop('disabled', true);
                            }
                        }
                    });
                });
            });
        });
    }

    $(document).ready(init);

})(jQuery);
