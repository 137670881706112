/**
 * Tabs: summary linked by aria controls id
 */
(function ($) {
  "use strict";

  $(window).ready(init);

  $(window).on("hashchange", function () {
    if (
      (window.location.hash != "" && window.location.hash === "#mesfavoris") ||
      window.location.hash === "#mescommandes" ||
      window.location.hash === "#mesprojets"
    ) {
      closeAll();
      openFromUrl(window.location.hash);
    } else {
      return;
    }
  });

  // Constants
  var BTN_SEL = "[data-tab]";
  var CONTAINER_SEL = "[data-tab-section]";

  /**
   * Init
   */
  function init() {
    var element = $(BTN_SEL);
    if (element.length === 0) {
      return;
    }

    closeAll();

    if (window.location.hash != "") {
      openFromUrl(window.location.hash);
    } else {
      openFirst();
    }

    $("body").on(
      "click",
      BTN_SEL + '[aria-expanded="false"]',
      function (event) {
        event.preventDefault();
        closeAll();
        var el = $(this);
        var panel = $("#" + el.attr("aria-controls"));
        var hash = "#" + el.attr("aria-controls").substr(4);
        el.attr("aria-expanded", "true");
        panel.attr("aria-hidden", "false");
        window.location.hash = hash;
      }
    );
  }

  function closeAll() {
    $(BTN_SEL).attr("aria-expanded", "false");
    $(CONTAINER_SEL).attr("aria-hidden", "true");
  }

  function openFirst() {
    var el = $(BTN_SEL).first().attr("aria-expanded", "true");
    var panel = $("#" + el.attr("aria-controls"));
    panel.attr("aria-hidden", "false");
  }

  function openFromUrl(hash) {
    var el = $('[aria-controls = "tab_' + hash.substr(1) + '"]');
    var panel = $("#" + el.attr("aria-controls"));
    el.attr("aria-expanded", "true");
    panel.attr("aria-hidden", "false");
  }
})(jQuery);
