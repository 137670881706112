/**
 * Select: turn a ul to select dropdown
 */
(function ($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = "[data-select]";
  var BTN_SEL = "[data-select-btn]";

  /**
   * Init
   */
  function init() {
    var element = $(CONTAINER_SEL);
    if (element.length === 0) {
      return;
    }

    buildSelect();
    triggerSelect();
    closeAll();

  }

  function buildSelect() {
    var select = $(CONTAINER_SEL);
    select.each(function() {
      var element = $(this);
      var labelText = element.attr("data-select-label");
      var label = $("<button class='Facets-label' data-select-btn>" + labelText + "</button>");
      element.wrap( "<div data-select-wrapper></div>").before(label)
    })
  }

  function triggerSelect() {

    $(window).on("click", function() {
      closeAll();
    });

    $('body').on("click", BTN_SEL + '[aria-expanded="false"]', function(event) {
      event.stopPropagation();
      closeAll();
      var el = $(this);
      var panel = el.next("[data-select]");
      el.attr("aria-expanded", "true");
      panel.attr("aria-hidden", "false");
    });

    $(CONTAINER_SEL).on("click", function(event) {
      event.stopPropagation();
    });

  }

  function closeAll() {
    $(BTN_SEL).attr("aria-expanded","false");
    $(CONTAINER_SEL).attr("aria-hidden", "true");
  }


})(jQuery);
