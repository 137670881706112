Zolpan.logintokenuser = Zolpan.logintokenuser || {};

Zolpan.logintokenuser = (function ($) {

  function init() {
    var element = $('#loginForm');
    if (element.length > 0 && Zolpan.vars.user == false) {
      var url_generate_token = Routing.generate('cromology_user_login_form');
      $.ajax({
        url: url_generate_token,
        success: function (response) {
          element.html(response);
        }
      });
    }
  }

  $(document).ready(init);

})(jQuery, {});
