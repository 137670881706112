Zolpan.MyProjects = Zolpan.MyProjects || {};
Zolpan.MyProjects = (function ($) {
  var vanillaModal = new VanillaModal.default();

  var $myProjectsSection = $(".mesprojets");

  function init() {
    $myProjectsSection.on("click", "[data-project-id]", function () {
      var projectId = $(this).data("project-id");
      var projectType = $(this).data("project-type");

      vanillaModal.open("#ConfirmModal");

      $("#yesButton").on("click", function () {
        if (projectType === "paint") {
          $.get(
            Routing.generate("zolpan.ajax.remove.paint_project", {
              projectId: projectId,
            })
          );
        }

        if (projectType === "wallpaper") {
          $.get(
            Routing.generate("zolpan.ajax.remove.wallpaper_project", {
              projectId: projectId,
            })
          );
        }

        location.reload();
      });

      $("#noButton").on("click", function () {
        vanillaModal.close("#ConfirmModal");
      });
    });
  }

  $(document).ready(init);
})(jQuery);
